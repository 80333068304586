import { BookingResponse } from '@/queries/booking';

import { fetchData } from '.';

type SuccessQueryResponse = {
  result: {
    success: true;
    data: {
      booking: BookingResponse;
    };
  };
};
type ErrorQueryResponse = {
  result: {
    success: false;
    error: {
      message: string;
    };
  };
};

type Props = {
  variables: {
    orderId: string;
  };
};

export async function getBooking({ variables }: Props) {
  const url = new URL(
    `${import.meta.env.VITE_API_URL}/bookings/${variables.orderId}`,
  );

  const res = await fetchData<SuccessQueryResponse | ErrorQueryResponse>({
    url,
    operationName: 'GetBooking',
    type: 'Query',
  });

  return res;
}
