import { Button } from '@/atoms/Button';
import { Text } from '@/atoms/Text';
import CloseSvg from '@/icons/close.svg';
import { Box, styled } from '@/panda/jsx';
import { a, useTransition } from '@react-spring/web';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { resolveValue, toast, useToaster } from 'react-hot-toast';

export const openedDialogs = new Set();

const Wrapper = styled(a.div, {
  base: {
    display: 'flex',
    position: 'fixed',
    zIndex: '999999',
    p: '16px',
    fontWeight: '500',
    top: '0px',
    left: '0px',
    w: '100%',
    color: 'white',
    '& svg': {
      color: '#414141',
    },
    boxShadow: '0px 4px 15px rgba(0,0,0,0.24)',
  },
  variants: {
    variant: {
      success: {
        bg: 'success',
        '& .close-button': {
          '&:hover': {
            bg: 'successHovered',
          },
          '& svg': {
            color: 'white',
          },
        },
      },
      error: {
        bg: 'error',
        '& svg': {
          fill: 'white',
        },
      },
      info: {
        bg: 'accent',
        color: 'primary.900',
        '& svg': {
          fill: 'primary.900',
        },
      },
      base: {},
      loading: {},
      blank: {},
      custom: {},
    },
  },
});

type PortalState = {
  show: boolean;
  portalContainer: Element | null;
};

export function Toast() {
  const [shouldRender, setShouldRender] = useState<PortalState>({
    show: false,
    portalContainer: null,
  });
  const { toasts, handlers } = useToaster({
    duration: 500000,
  });
  const { startPause, endPause } = handlers;

  const allToasts = toasts
    .filter((t) => t.visible)
    .map((t, i) => ({
      ...t,
      index: i,
    }));

  useEffect(() => {
    if (allToasts.length > 0 && !shouldRender.show) {
      setShouldRender({
        show: true,
        portalContainer: document.body,
      });
    }
    if (allToasts.length > 1) {
      const first = allToasts[allToasts.length - 1];
      toast.dismiss(first.id);
    }

    if (allToasts.length > 0) {
      openedDialogs.add('toasts');
    } else {
      openedDialogs.delete('toasts');
    }
  }, [allToasts]);

  const transition = useTransition(allToasts, {
    keys(item) {
      return item.id;
    },
    config: {
      mass: 3.2,
      friction: 72,
      tension: 640,
    },
    from: {
      opacity: 0,
      y: -80,
    },
    enter: {
      opacity: 1,
      y: 0,
    },
    leave: {
      opacity: 0,
      y: -80,
      onRest() {
        if (allToasts.length === 0) {
          setShouldRender({
            show: false,
            portalContainer: null,
          });
        }
      },
    },
  });

  const toastFragment = transition((style, t) => {
    return (
      <Wrapper
        variant={t.type ?? 'base'}
        style={style}
        css={{
          alignItems: 'center',
        }}
      >
        <Text textSize="body2" flex="1" mr="8px" color="inherit">
          {resolveValue(t.message, t)}
        </Text>
        <Button
          justifyContent="center"
          alignItems="center"
          w="40px"
          h="40px"
          ml="auto"
          p="0px"
          mb="auto"
          className="close-button"
          borderRadius="50%"
          transition="background-color 240ms ease"
          variant="none"
          onClick={() => {
            toast.dismiss(t.id);
          }}
          css={{
            '& svg': {
              w: '22px',
              h: '22px',
            },
          }}
        >
          <CloseSvg />
        </Button>
      </Wrapper>
    );
  });

  if (shouldRender.show && shouldRender.portalContainer) {
    return ReactDOM.createPortal(
      <Box
        position="fixed"
        top="0px"
        right="0px"
        left="0px"
        zIndex="5000"
        onMouseEnter={startPause}
        onMouseLeave={endPause}
        id="toast-container"
      >
        {toastFragment}
      </Box>,
      shouldRender.portalContainer,
    );
  }
  return null;
}

let lastActiveToast = '';

function showToast(message: string, type: 'info' | 'error' = 'info') {
  lastActiveToast = toast(message, {
    // @ts-ignore
    type,
  });
}

function dismissLastActiveToast() {
  toast.dismiss(lastActiveToast);
}

export { showToast, dismissLastActiveToast };
