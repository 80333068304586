import { Box } from '@/panda/jsx/box';
import { styled } from '@/panda/jsx/factory';
import { Flex } from '@/panda/jsx/flex';
import cs from 'classnames';
import { ComponentProps, ReactNode, forwardRef } from 'react';

import { Text } from '../Text';

const Element = styled('input');
type Props = Omit<ComponentProps<typeof Element>, 'label'> & {
  additionalText?: string;
  isErrored?: boolean;
  label?: string;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  containerClassName?: string;
};

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      additionalText,
      isErrored = false,
      css: cssProp = {},
      label,
      rightIcon,
      leftIcon,
      containerClassName,
      placeholder = ' ',
      ...props
    },
    ref,
  ) => {
    return (
      <Flex
        flexDir="column"
        className={containerClassName}
        w="100%"
        borderRadius="8px"
      >
        <Flex position="relative" alignItems="center" borderRadius="inherit">
          <Element
            ref={ref}
            bg="white"
            border="1px solid token(colors.greyscale.12)"
            borderRadius="inherit"
            transition="border-color 140ms ease-out"
            w="100%"
            h="100%"
            outline="none"
            color="inherit"
            fontFamily="inherit"
            fontSize="16px"
            placeholder={placeholder}
            className={cs({
              'is-errored': isErrored,
              'has-placeholder': !label && !!placeholder.trim(),
              'with-right-icon': !!rightIcon,
              'with-left-icon': !!leftIcon,
            })}
            {...props}
            css={{
              ...cssProp,
              h: '56px',
              p: '24px 16px 8px 16px',
              '&.has-placeholder': {
                py: '8px',
              },
              '&.with-right-icon': {
                pr: '46px',
              },
              '&.with-left-icon': {
                pl: '46px',
              },
              '&.is-errored': {
                borderColor: 'error!',
                '& ~ .additional-text': {
                  color: 'error',
                },
              },
              '&::placeholder': {
                color: 'greyscale.60',
              },
              '&:focus:not(:read-only):not([disabled]) ~ .label, &:not(:placeholder-shown) ~ .label':
                {
                  fontSize: '12px',
                  lineHeight: '16px',
                  transform: 'translateY(-11px)',
                  color: 'greyscale.38',
                },
              '&:focus:not(:read-only):not([disabled])': {
                borderColor: 'primary.500',
                '& ~ .decorator': {
                  opacity: '1',
                },
              },
              '&:hover:not(:focus):not([disabled])': {
                borderColor: 'greyscale.38',
              },
            }}
          />
          <Box
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            borderRadius="inherit"
            pointerEvents="none"
            opacity="0"
            transition="opacity 140ms ease-out"
            className="decorator"
            color={isErrored ? 'error' : 'colors.primary.500'}
            boxShadow="0px 0px 0px 1px currentColor"
          />
          {label && (
            <Text
              className="label"
              position="absolute"
              color="greyscale.60"
              pointerEvents="none"
              left="16px"
              transition="font-size 140ms ease-out, transform 140ms ease-out, color 140ms ease-out"
            >
              {label}
            </Text>
          )}
          {leftIcon && (
            <Flex position="absolute" left="16px">
              {leftIcon}
            </Flex>
          )}
          {rightIcon && (
            <Flex position="absolute" right="16px">
              {rightIcon}
            </Flex>
          )}
        </Flex>
        {additionalText && (
          <Text
            textSize="caption"
            ml="16px"
            mt="4px"
            className="additional-text"
            transition="color 140ms ease-out"
            color={isErrored ? 'error' : 'greyscale.60'}
            letterSpacing="0.4px"
          >
            {additionalText}
          </Text>
        )}
      </Flex>
    );
  },
);
