import { styled } from '@/panda/jsx/factory';
import { Flex } from '@/panda/jsx/flex';
import { useAppStore } from '@/store/Store';
import { a, useSpring } from '@react-spring/web';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const SpringBox = styled(a.div);

const totalSteps = 7;

export function StepProgressBar() {
  const { pathname } = useLocation();
  const step = useAppStore((s) => s.step);

  const [activeStep, setActiveStep] = useState(() => {
    const isThankYouPage = pathname.includes('thank-you');
    if (isThankYouPage) return 7;
    return 1;
  });
  const tot = -100 + (100 / totalSteps) * activeStep;

  const spring = useSpring({
    x: tot,
  });

  useEffect(() => {
    const isPetCategory = pathname === '/cane' || pathname === '/gatto';
    const isThankYouPage = pathname.includes('thank-you');

    if (!isPetCategory && !step) {
      setActiveStep(1);
    }
    if (isPetCategory && !step) {
      setActiveStep(2);
    }
    if (isPetCategory && step && !isThankYouPage) {
      setActiveStep((p) => {
        const newVal = step + 2;
        return newVal > p ? p + 1 : p - 1;
      });
    }
    if (isThankYouPage) {
      setActiveStep(7);
    }
  }, [pathname, step]);

  return (
    <Flex
      position="absolute"
      top="100%"
      left="0"
      w="100%"
      h="4px"
      bg="primary.300"
    >
      <SpringBox
        bg="primary.500"
        w="100%"
        position="absolute"
        left="0"
        h="100%"
        style={{
          x: spring.x.to((v) => `${v}%`),
        }}
      />
    </Flex>
  );
}
