import { BaseDatepicker } from '@/molecoles/BaseDatepicker';
import { Flex } from '@/panda/jsx/flex';
import dayjs from 'dayjs';

dayjs.locale('it');

function findMonthDifferenceToNextAvailableMonth(dates: string[]) {
  const currentDate = dayjs();

  for (const dateString of dates) {
    const inputDate = dayjs(dateString, 'YYYY-MM-DD');
    if (
      inputDate.isAfter(currentDate, 'month') ||
      (inputDate.year() === currentDate.year() + 1 &&
        inputDate.month() === 0 &&
        currentDate.month() === 11)
    ) {
      const currentMonth = currentDate.month();
      const currentYear = currentDate.year();
      const futureMonth = inputDate.month();
      const futureYear = inputDate.year();
      let monthDifference;

      if (futureYear === currentYear) {
        monthDifference = futureMonth - currentMonth;
      } else {
        monthDifference = 12 - currentMonth + futureMonth;
      }

      return monthDifference;
    }
  }

  return 0;
}

type Props = {
  selectedDate: Date | undefined;
  activeDates?: string[];
  setSelectedDate(date: Date | undefined): void;
};

export function InputDatePicker({
  selectedDate,
  setSelectedDate,
  activeDates,
}: Props) {
  const todayActiveDates = activeDates?.some((d) =>
    dayjs(d).isSame(dayjs(), 'month'),
  );

  return (
    <Flex w="100%" alignContent="start" className="input-datepicker-container">
      <BaseDatepicker
        initialDate={selectedDate}
        activeDates={activeDates}
        onChange={setSelectedDate}
        dayzedProps={{
          minDate: activeDates
            ? dayjs(activeDates[0]).toDate()
            : dayjs().subtract(1, 'day').toDate(),
          offset:
            todayActiveDates && activeDates
              ? findMonthDifferenceToNextAvailableMonth(activeDates) - 1
              : 0,
        }}
      />
    </Flex>
  );
}
