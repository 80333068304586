import { Input } from '@/atoms/Input';
import { Text } from '@/atoms/Text';
import EmailSvg from '@/icons/email.svg';
import InfoSvg from '@/icons/info.svg';
import PhoneSvg from '@/icons/phone.svg';
import { dismissLastActiveToast } from '@/molecoles/Toast';
import { useNoResultsDialogStore } from '@/organisms/NoResultsDialog';
import { StepSectionLayout } from '@/organisms/StepSectionLayout';
import { css } from '@/panda/css/css';
import { Grid } from '@/panda/jsx/grid';
import { Box, Flex, styled } from '@/panda/jsx/index';
import { GetSlotsQueryVariables } from '@/queries/slots';
import { ContactInfoSchema, useAppStore } from '@/store/Store';
import { useSpringScroll } from '@/utils/hooks';
import { logQueryError, logger } from '@/utils/logger';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { useGetSlotsQuery } from '../Step4/Step4';
import { ContactUsDialog } from './ContactUsDialog';
import { GooglePlacesInput } from './GooglePlacesInput';
import { NoLocationAvailable } from './NoLocationAvailableDialog';

function MissingAddressInfoBox() {
  return (
    <Flex
      alignItems="center"
      borderRadius="8px"
      p="8px"
      w="100%"
      bg="accent"
      gridColumn="1 / -1"
    >
      <InfoSvg
        className={css({
          flexShrink: '0',
          mr: '10px',
        })}
      />
      <Text textSize="subtitle1" color="primary.900">
        Ci manca qualche dato per determinare se siamo nella tua zona. Se
        riscontri problemi <ContactUsDialog />
      </Text>
    </Flex>
  );
}

export type FormState = z.infer<typeof ContactInfoSchema>;

export function Step3() {
  const handleOpenNoResultsDialog = useNoResultsDialogStore(
    (s) => s.handleOpen,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [openNoLocationAvailableDialog, setOpenNoLocationAvailableDialog] =
    useState(false);

  const [, setQueryParams] = useSearchParams();
  const { scrollToElement } = useSpringScroll();
  const setContactInfo = useAppStore((s) => s.setContactInfo);
  const setAvailableDates = useAppStore((s) => s.setAvailableDates);
  const setDate = useAppStore((s) => s.setDate);
  const step = useAppStore((s) => s.step ?? 0);

  const initialContactFormData = useAppStore((s) => s.contactInfo);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    resetField,
  } = useForm<FormState>({
    mode: 'onBlur',
    resolver: zodResolver(ContactInfoSchema),
    shouldFocusError: false,
    defaultValues: {
      firstName: initialContactFormData?.firstName ?? '',
      lastName: initialContactFormData?.lastName ?? '',
      email: initialContactFormData?.email ?? '',
      telephone: initialContactFormData?.telephone ?? '',
      location: {
        address: initialContactFormData?.location?.address ?? '',
        googlePlaceId: initialContactFormData?.location?.googlePlaceId ?? '',
        coordinates: {
          lat: initialContactFormData?.location?.coordinates?.lat ?? 0,
          lng: initialContactFormData?.location?.coordinates?.lng ?? 0,
        },
        components: {
          route: initialContactFormData?.location?.components?.route ?? '',
          streetNumber:
            initialContactFormData?.location?.components?.streetNumber ?? '',
          city: initialContactFormData?.location?.components?.city ?? '',
          province:
            initialContactFormData?.location?.components?.province ?? '',
          provinceCode:
            initialContactFormData?.location?.components?.provinceCode ?? '',
          region: initialContactFormData?.location?.components?.region ?? '',
          postalCode:
            initialContactFormData?.location?.components?.postalCode ?? '',
          country: initialContactFormData?.location?.components?.country ?? '',
          countryCode:
            initialContactFormData?.location?.components?.countryCode ?? '',
        },
      },
    },
  });

  const [showGooglePlacesError, setShowGooglePlacesError] = useState(false);
  const formIsSubmitting = useRef(isSubmitting);

  /**
   * We do this to ensure the correct input registration order
   * so when some input has error, react-hook-form
   * will focus the right one (since the library focus logic follow the registration order)
   *
   * This is because we need to destructure out telephoneRef
   * from telephone (otherwise this wouldn't be necessary)
   */
  const addressRegister = register('location.address');
  const civicNumberRegister = register('location.components.streetNumber');
  const capNumberRegister = register('location.components.postalCode');
  const firstNameRegister = register('firstName');
  const lastNameRegister = register('lastName');
  const emailRegister = register('email');

  useEffect(() => {
    if (isSubmitting) {
      formIsSubmitting.current = true;
    }
  }, [isSubmitting]);
  useEffect(() => {
    const allErrors = Object.entries(errors)
      .map(([, value]) => value)
      .flat(0);

    let firstAvailableError = allErrors[0];
    const addressError = errors.location?.address;
    const postalCodeError = errors.location?.components?.postalCode;
    const streetNumberError = errors.location?.components?.streetNumber;

    if (postalCodeError) {
      firstAvailableError = postalCodeError;
    }
    if (streetNumberError) {
      firstAvailableError = streetNumberError;
    }
    if (addressError) {
      firstAvailableError = addressError;
    }

    if (formIsSubmitting.current) {
      setShowGooglePlacesError(
        !!postalCodeError || !!streetNumberError || !!addressError,
      );
    }

    const scrollContainer = document.querySelector<HTMLElement>('main')!;

    if (
      formIsSubmitting.current &&
      firstAvailableError?.ref instanceof HTMLElement
    ) {
      scrollToElement(firstAvailableError.ref, 80, scrollContainer);
      firstAvailableError.ref.focus();
      formIsSubmitting.current = false;
    } else {
      if (
        firstAvailableError &&
        !firstAvailableError?.ref &&
        !getValues('telephone')
      ) {
        const element = document.getElementById(
          'telephone-number',
        ) as HTMLElement;
        scrollToElement(element, 80, scrollContainer);
        formIsSubmitting.current = false;
        element.focus();
      }
    }
  }, [errors]);

  const serviceId = useAppStore((s) => s.activeService?.id ?? '');

  const variables: GetSlotsQueryVariables = {
    postalCode: getValues('location.components.postalCode'),
    serviceId,
  };

  const { refetch } = useGetSlotsQuery({
    variables,
    enabled: false,
    key: 'date-slots',
  });

  return (
    <>
      <styled.form
        id="contact-form"
        display="grid"
        maxW="600px"
        mx="auto"
        w="100%"
        gap="48px"
        position="relative"
        alignContent="start"
        onSubmit={handleSubmit(async (values) => {
          setIsLoading(true);
          dismissLastActiveToast();

          const validateContactData = ContactInfoSchema.safeParse(values);
          if (validateContactData.success) {
            setContactInfo(validateContactData.data);

            const { data, error } = await refetch();

            if (!data) {
              console.log(data, error);
              return;
            }

            if (data && !data.result.success) {
              setIsLoading(false);
              const errorMessage = data.result.error.message.toLowerCase();
              logQueryError('GetSlots', errorMessage);

              if (errorMessage.includes('impossibile trovare questa zona')) {
                setOpenNoLocationAvailableDialog(true);
              }

              return;
            }
            if (data.result.success) {
              if (data.result.data.dates.length === 0) {
                setIsLoading(false);
                handleOpenNoResultsDialog();
                return;
              }
              setDate(undefined);
              setAvailableDates(data.result.data.dates);
            }

            setQueryParams({
              step: `${Number(step + 1)}`,
            });
          } else {
            setIsLoading(false);
            logger({
              message: `[Validating ContactInfoSchema]: ${validateContactData.error.message}`,
              type: 'error',
              payload: validateContactData.error,
            });
          }
        })}
      >
        {isLoading && (
          <Flex
            position="absolute"
            top="-8px"
            bottom="-32px"
            left="-16px"
            right="-16px"
            zIndex="10"
            justifyContent="center"
            alignItems="center"
            bg="rgba(255,255,255,0.94)"
            borderRadius="8px"
          >
            <Box className="loader" />
          </Flex>
        )}
        <StepSectionLayout
          title="Dove ti trovi?"
          content="Inserisci via, numero civico e città (ci serve saperlo per dirti se copriamo la tua zona)"
        >
          <Grid
            w="100%"
            alignContent="start"
            gap="16px"
            gridTemplateColumns="1fr 1fr"
          >
            <GooglePlacesInput
              errorMessage={errors?.location?.address?.message}
              register={addressRegister}
              initialValue={getValues('location.address')}
              onChange={(values) => {
                setValue('location', values, {
                  shouldValidate: true,
                });
              }}
              onReset={() => {
                resetField('location', {
                  defaultValue: {
                    ...getValues('location'),
                    address: '',
                  },
                });
              }}
            />
            <Input
              label="N Civico"
              {...civicNumberRegister}
              isErrored={!!errors.location?.components?.streetNumber?.message}
              additionalText={
                errors.location?.components?.streetNumber?.message
              }
            />
            <Input
              label="CAP"
              {...capNumberRegister}
              isErrored={!!errors.location?.components?.postalCode?.message}
              additionalText={errors.location?.components?.postalCode?.message}
            />
            {showGooglePlacesError && <MissingAddressInfoBox />}
          </Grid>
        </StepSectionLayout>
        <StepSectionLayout title="Ci lasci un contatto per confermarti la disponibilità?">
          <Grid w="100%" alignContent="start" gap="16px">
            <Input
              label="Nome"
              {...firstNameRegister}
              isErrored={!!errors.firstName?.message}
              additionalText={errors.firstName?.message}
            />
            <Input
              label="Cognome"
              {...lastNameRegister}
              isErrored={!!errors.lastName?.message}
              additionalText={errors.lastName?.message}
            />
            <Input
              label="Email"
              type="email"
              rightIcon={<EmailSvg />}
              {...emailRegister}
              isErrored={!!errors.email?.message}
              additionalText={errors.email?.message}
            />
            <PatternFormat
              format="+39 ### ### ####"
              allowEmptyFormatting
              mask="_"
              customInput={Input}
              id="telephone-number"
              rightIcon={<PhoneSvg />}
              defaultValue={getValues('telephone')}
              label="Telefono"
              isErrored={!!errors.telephone?.message}
              additionalText={errors.telephone?.message}
              onValueChange={(val) => setValue('telephone', val.value)}
            />
            <Text
              textSize="caption"
              color="greyscale.60"
              letterSpacing="0.4px"
              px="14px"
              mt="4px"
            >
              Proseguendo acconsenti ai termini della{' '}
              <a
                target="_blank"
                href={import.meta.env.VITE_PRIVACY_POLICY_URL}
                className={css({
                  fontWeight: '700',
                  color: 'primary.900',
                  textDecoration: 'underline',
                })}
              >
                Privacy Policy
              </a>{' '}
              per il trattamento dei dati.
            </Text>
          </Grid>
        </StepSectionLayout>
      </styled.form>
      <NoLocationAvailable
        isOpen={openNoLocationAvailableDialog}
        onClose={() => setOpenNoLocationAvailableDialog(false)}
        location={getValues('location.address')}
      />
    </>
  );
}
