import { Button } from '@/atoms/Button';
import { Link } from '@/atoms/Link';
import { Text } from '@/atoms/Text';
import EmailSvg from '@/icons/email.svg';
import PageNotFoundSvg from '@/icons/page-not-found.svg';
import PhoneSvg from '@/icons/phone.svg';
import WhatsappSvg from '@/icons/whatsapp.svg';
import { Dialog } from '@/molecoles';
import { css } from '@/panda/css/css';
import { Flex } from '@/panda/jsx/flex';
import { Grid } from '@/panda/jsx/grid';
import { useState } from 'react';

export function ContactUsDialog() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        variant="none"
        onClick={() => setIsOpen(true)}
        className={css({
          display: 'inline',
          textDecoration: 'underline',
          cursor: 'pointer',
          fontWeight: '500',
        })}
      >{`contattaci >`}</Button>
      <Dialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        maxW="400px"
        p={{
          base: '16px',
          lg: '24px',
        }}
        css={{
          '& .react-aria-Dialog': {
            display: 'grid',
            gap: '24px',
            justifyItems: 'center',
            alignContent: 'start',
            position: 'relative',
          },
        }}
      >
        <Flex justifyContent="center">
          <PageNotFoundSvg />
        </Flex>
        <Text
          as="p"
          color="primary.900"
          font="serif"
          textAlign="center"
          textSize="headline6"
          maxW="320px"
          fontWeight="700"
        >
          Hai riscontrato un problema durante la prenotazione?
        </Text>
        <Text
          as="p"
          textAlign="center"
          textSize="body1"
          maxW="295px"
          color="primary.900"
        >
          Siamo attivi dalle 9:00 alle 18:00, dal Lunedì al Venerdì.
        </Text>
        <Grid gap="16px" w="100%">
          <Link
            leftIcon={<WhatsappSvg />}
            to="https://wa.me/3517306666"
            variant="outlined"
            target="_blank"
          >
            Whatsapp
          </Link>
          <Link
            leftIcon={<EmailSvg />}
            to="mailto:info@pelomatto.it"
            variant="outlined"
            target="_blank"
          >
            Email
          </Link>
          <Link
            leftIcon={<PhoneSvg />}
            to="tel:3517306666"
            variant="outlined"
            target="_blank"
          >
            Chiamaci
          </Link>
          <Button onClick={() => setIsOpen(false)}>Annulla</Button>
        </Grid>
      </Dialog>
    </>
  );
}
