import { logger } from '../utils';

type FetchDataProps = {
  url: URL;
  operationName: string;
  type: 'Query' | 'Mutation';
  opts?: RequestInit;
};

async function fetchData<T>({
  url,
  type,
  operationName,
  opts = {},
}: FetchDataProps) {
  logger({
    message: `[${type}]: ${operationName}`,
    type: type === 'Query' ? 'info' : 'warn',
    payload: {
      url: url.toString(),
    },
  });

  return (await (
    await fetch(url, {
      method: 'GET',
      ...(opts || {}),
      headers: {
        ...(opts?.headers || {}),
        'Content-Type': 'application/json',
      },
    })
  ).json()) as T;
}

function setQueryParamsToUrl(url: URL, variables: Record<string, string>) {
  Object.entries(variables).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });
}

export { fetchData, setQueryParamsToUrl };
