import { useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { trackEvent } from 'src/analytics';

import { DataLayerObject } from '../../analytics/types';

export function Analytics() {
  const [searchParams] = useSearchParams();
  const { pathname, ...location } = useLocation();
  const isFirstMount = useRef(true);
  const stepQueryParam = searchParams.get('step');

  useEffect(() => {
    function getPetCategory() {
      return pathname === '/cane' ? 'dog' : 'cat';
    }
    function getStepName(): DataLayerObject['stepName'] {
      switch (Number(stepQueryParam)) {
        default:
        case 1: {
          return 'petSize';
        }
        case 2: {
          return 'service';
        }
        case 3: {
          return 'recap';
        }
        case 4: {
          return 'userData';
        }
        case 5: {
          return 'dateTimeSelection';
        }
      }
    }

    if (!isFirstMount.current) {
      if (pathname.includes('thank-you')) {
        trackEvent({
          event: 'stepCompleted',
          petCategory: getPetCategory(),
          stepName: 'booking',
        });
      } else if (pathname !== '/' && !stepQueryParam) {
        trackEvent({
          event: 'stepCompleted',
          petCategory: getPetCategory(),
          stepName: 'petCategory',
        });
      } else if (pathname !== '/' && stepQueryParam) {
        trackEvent({
          event: 'stepCompleted',
          petCategory: getPetCategory(),
          stepName: getStepName(),
        });
      }
    }
    if (isFirstMount.current) {
      isFirstMount.current = false;
    }
  }, [location]);

  return null;
}
