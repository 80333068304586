import { Button } from '@/atoms/Button';
import { Text } from '@/atoms/Text';
import ArrowLeft from '@/icons/arrow_back.svg';
import ArrowRight from '@/icons/arrow_back.svg';
import { css } from '@/panda/css/css';
import { Box } from '@/panda/jsx/box';
import { Flex } from '@/panda/jsx/flex';
import { Grid } from '@/panda/jsx/grid';
import cs from 'classnames';
import dayjs from 'dayjs';
import { Props as DaysedProps, useDayzed } from 'dayzed';
import { useEffect, useState } from 'react';

const months = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
];
const days = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];

type Props = {
  initialDate?: Date;
  onChange(value: Date): void;
  dayzedProps?: Omit<DaysedProps, 'onDateSelected'>;
  activeDates?: string[];
};

export function BaseDatepicker({
  onChange,
  initialDate,
  dayzedProps,
  activeDates,
}: Props) {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    initialDate,
  );
  const [offset, setOffset] = useState(dayzedProps?.offset);

  const { calendars, getDateProps, getBackProps, getForwardProps } = useDayzed({
    selected: selectedDate,
    firstDayOfWeek: 1,
    ...(dayzedProps ?? {}),
    offset,
    onOffsetChanged(v) {
      setOffset(v);
    },
    onDateSelected(newDate) {
      setSelectedDate(newDate.date);
      onChange(newDate.date);
    },
  });

  useEffect(() => {
    setSelectedDate(initialDate);
  }, [initialDate]);

  return calendars.map((calendar) => {
    return (
      <Flex
        key={`${calendar.month}${calendar.year}`}
        flexDir="column"
        bg="white"
        boxShadow="0px 4px 24px rgba(0,0,0,0.048)"
        borderRadius="12px"
        className="datepicker-wrapper"
        w="100%"
        p={{
          base: '12px',
          xsm: '24px',
        }}
      >
        <Flex
          flexDir="column"
          mx="auto"
          borderRadius="8px"
          overflow="hidden"
          w="100%"
          className="datepicker-header"
          mb="8px"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            mb="12px"
            css={{
              '& button': {
                w: '36px',
                h: '36px',
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#414141',
                p: '0px',
                transition: 'background-color 140ms ease-out',
                '&:hover:not([disabled])': {
                  bg: 'primary.300',
                },
                '&:first-child': {
                  mr: '8px',
                },
                '&[disabled]': {
                  opacity: '0.4',
                  cursor: 'unset',
                },
              },
              '& svg': {
                w: '18px',
                h: '18px',
              },
            }}
          >
            <Button variant="none" {...getBackProps({ calendars })}>
              <ArrowLeft />
            </Button>
            <Flex color="primary.700">
              {months[calendar.month]} {calendar.year}
            </Flex>
            <Button variant="none" {...getForwardProps({ calendars })}>
              <ArrowRight
                className={css({
                  transform: 'rotate(180deg)',
                })}
              />
            </Button>
          </Flex>
          <Flex justifyContent="space-between">
            {days.map((d) => (
              <Text
                w="36px"
                textSize="body2"
                textAlign="center"
                fontWeight="500"
                key={`${calendar.month}${calendar.year}${d}`}
                _last={{
                  mr: '0px',
                }}
              >
                {d}
              </Text>
            ))}
          </Flex>
        </Flex>
        <Grid
          gridTemplateColumns="repeat(7, max-content)"
          justifyItems="center"
          justifyContent="space-between"
          mx="auto"
          className="datepicker-dates-grid"
          w="100%"
          gap={{
            xxs: '2px',
          }}
        >
          {calendar.weeks.map((week, weekIndex) =>
            week.map((dateObj, index) => {
              const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;
              if (!dateObj) {
                return (
                  <div
                    key={key}
                    style={{
                      display: 'inline-block',
                      width: 'calc(100% / 7)',
                      border: 'none',
                      background: 'transparent',
                    }}
                  />
                );
              }
              const { date, selected, selectable, today } = dateObj;

              const formattedDate = dayjs(date).format('YYYY-MM-DD');

              const isDisabled = activeDates
                ? activeDates.includes(formattedDate)
                  ? false
                  : true
                : undefined;

              return (
                <Button
                  {...getDateProps({
                    dateObj,
                    disabled: isDisabled,
                  })}
                  variant="none"
                  p="0"
                  key={key}
                  w={{
                    base: '32px',
                    xsm: '40px',
                  }}
                  h={{
                    base: '32px',
                    xsm: '40px',
                  }}
                  justifyContent="center"
                  alignItems="center"
                  fontWeight="400"
                  className={cs({
                    'is-selected': selected,
                    'is-today': today,
                    'is-not-selectable': !selectable,
                    ...(isDisabled !== undefined
                      ? {
                          'is-disabled': isDisabled,
                        }
                      : {}),
                  })}
                  css={{
                    position: 'relative',
                    borderRadius: '50%',
                    transition: 'background-color 140ms ease-out',
                    '&:hover:not(.is-selected):not(.is-not-selectable):not(.is-disabled)':
                      {
                        bg: 'primary.300',
                      },
                    '&.is-selected': {
                      bg: 'primary.700',
                      color: 'primary.50',
                    },
                    '&.is-not-selectable, &.is-disabled': {
                      opacity: '0.24',
                      cursor: 'not-allowed',
                    },
                  }}
                  _hover={{
                    '&:not(.is-selected)': {
                      color: '#414141',
                    },
                  }}
                >
                  <Box zIndex="1">{date.getDate()}</Box>
                </Button>
              );
            }),
          )}
        </Grid>
      </Flex>
    );
  });
}
