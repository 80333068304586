import { Button } from '@/atoms/Button';
import { dismissLastActiveToast, showToast } from '@/molecoles/Toast';
import { styled } from '@/panda/jsx/factory';
import { useAppStore } from '@/store/index';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export function Footer() {
  const [, setQueryParams] = useSearchParams();

  const { pathname } = useLocation();
  const [buttonType, setButtonType] = useState<'button' | 'submit'>('button');
  const navigateTo = useNavigate();

  const activeService = useAppStore((s) => s.activeService);
  const timeSlot = useAppStore((s) => s.timeSlot);
  const petCategory = useAppStore((s) => s.petCategory);
  const step = useAppStore((s) => s.step);
  const selectedPetAttributes = useAppStore((s) => s.petAttributes ?? []);

  const isThankYouPage = pathname.includes('thank-you');
  const backButtonIsDisabled = pathname === '/' || isThankYouPage;

  function navigateToNextStep(_nextStep: number) {
    setQueryParams({
      step: `${_nextStep}`,
    });
  }

  const isContactFormStep = step === 3;
  const isBookingStep = step === 4;
  const isDogStep = petCategory === 'dog';
  const isCatStep = petCategory === 'cat';

  /**
   * Dismiss any active toast when page changes
   */
  useEffect(() => {
    dismissLastActiveToast();
  }, [pathname, step]);

  useEffect(() => {
    setButtonType(isContactFormStep || isBookingStep ? 'submit' : 'button');
  }, [isContactFormStep]);

  if (isThankYouPage) return null;

  return (
    <styled.footer
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      position="sticky"
      bottom="0px"
      bg="white"
      zIndex="50"
      w="100%"
      boxShadow="0px 4px 15px rgba(0,0,0,0.24)"
      p="12px"
    >
      <Button
        variant="outlined"
        disabled={backButtonIsDisabled}
        onClick={() => {
          if (backButtonIsDisabled) return;
          if (petCategory && step === undefined) {
            navigateTo('/');
            return;
          }
          if (typeof step === 'number') {
            const nextPrevStep = step - 1;

            if (nextPrevStep === 0) {
              navigateTo(`/${petCategory === 'cat' ? 'gatto' : 'cane'}`);
            } else {
              setQueryParams({
                step: `${nextPrevStep}`,
              });
            }
          }
        }}
      >
        INDIETRO
      </Button>
      <Button
        disabled={isBookingStep && !timeSlot}
        type={buttonType}
        {...(buttonType
          ? {
              form: isBookingStep ? 'booking-form' : 'contact-form',
            }
          : {})}
        onClick={() => {
          if (isContactFormStep) {
            return;
          }

          if (pathname === '/' && !petCategory) {
            showToast('Devi selezionare un animale per poter andare avanti!');
            return;
          }

          if (pathname === '/' && petCategory) {
            navigateTo(`/${petCategory === 'cat' ? 'gatto' : 'cane'}`);
            return;
          }

          if (isCatStep) {
            if (step === undefined) {
              if (
                !selectedPetAttributes.find((a) => a.type === 'petHairLength')
              ) {
                showToast('Devi selezionare il tipo di pelo del tuo gatto!');
              } else {
                setQueryParams({
                  step: '1',
                });
              }
              return;
            }
          }

          if (isDogStep) {
            if (step === undefined) {
              if (
                !selectedPetAttributes.find((a) => a.type === 'petHairLength')
              ) {
                showToast('Devi selezionare il tipo di pelo del cane!');
              } else if (
                !selectedPetAttributes.find((a) => a.type === 'petSize')
              ) {
                showToast('Devi selezionare la taglia del cane!');
              } else {
                setQueryParams({
                  step: '1',
                });
              }
              return;
            }
          }

          if (typeof step === 'number') {
            const nextStep = step + 1;

            if (nextStep === 5) return;
            if (nextStep === 2 && !activeService) {
              showToast('Devi selezionare un servizio');
              return;
            }

            navigateToNextStep(nextStep);
          }
        }}
      >
        {isBookingStep ? 'PRENOTA' : 'PROSEGUI'}
      </Button>
    </styled.footer>
  );
}
