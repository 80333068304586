import { Button } from '@/atoms/Button';
import { Text } from '@/atoms/Text';
import CatSvg from '@/icons/cat.svg';
import DogSvg from '@/icons/dog.svg';
import ProhibitSvg from '@/icons/prohibit.svg';
import { css } from '@/panda/css/css';
import { Flex } from '@/panda/jsx/flex';
import { AppState } from '@/store/Store';
import { useMediaQuery } from '@uidotdev/usehooks';

function OpenDatepickerButton() {
  return (
    <Flex mt="16px" justifyContent="center">
      <Button
        variant="outlined"
        textSize="button-large"
        bg="transparent"
        onClick={() => {}}
      >
        Cambia data
      </Button>
    </Flex>
  );
}

type Props = {
  notAvailableDates: boolean;
  step: AppState['petCategory'];
};

export function NoDateResultBox({ notAvailableDates, step }: Props) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <Flex
        bg="white"
        borderRadius="8px"
        p="48px 16px"
        flexDir="column"
        alignItems="center"
        css={{
          '& .pet-category-svg': {
            maxH: '45px',
            h: '100%',
            zIndex: '1',
          },
        }}
      >
        <Flex position="relative" alignItems="center" justifyContent="center">
          {notAvailableDates && (
            <ProhibitSvg
              className={css({
                position: 'absolute',
              })}
            />
          )}
          {step === 'cat' ? (
            <CatSvg className="pet-category-svg" />
          ) : (
            <DogSvg className="pet-category-svg" />
          )}
        </Flex>
        <Text mt="24px" color="primary.900" maxW="260px" textAlign="center">
          {notAvailableDates
            ? 'Ooops! Non c’è disponibilità per la tua data.'
            : 'Devi selezionare una data'}
        </Text>
      </Flex>
      {notAvailableDates && isMobile && <OpenDatepickerButton />}
    </>
  );
}
