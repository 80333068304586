import { Radio } from '@/atoms/Radio';
import { Text } from '@/atoms/Text';
import { cat } from '@/data/data.json';
import { showToast } from '@/molecoles/Toast';
import { StepSectionLayout } from '@/organisms/StepSectionLayout';
import { styled } from '@/panda/jsx/factory';
import { Grid } from '@/panda/jsx/grid';
import { PetHairAttributeSchema, getPetHairLength } from '@/store/Store';
import { useAppStore } from '@/store/index';
import { logger } from '@/utils/logger';
import { RadioGroup } from 'react-aria-components';
import { useSearchParams } from 'react-router-dom';
import slugify from 'slugify';
import { Steps } from 'src/components/templates/Steps';

const StyledRadioGroup = styled(RadioGroup);

function BaseLayout() {
  const petAttributes = useAppStore((s) => s.petAttributes ?? []);
  const setPetAttribute = useAppStore((s) => s.setPetAttribute);

  const hairAttributes = cat.attrs.petHairLength;
  const initialHairValue = getPetHairLength(petAttributes)?.value;

  return (
    <Grid
      alignContent="start"
      w="100%"
      maxW="600px"
      gap={{
        base: '40px',
        md: '64px',
      }}
    >
      <StepSectionLayout title="Che tipo di pelo ha il tuo gatto?">
        <StyledRadioGroup
          display="grid"
          gap="8px"
          value={initialHairValue}
          onChange={(value) => {
            const parsedValue = PetHairAttributeSchema.safeParse({
              type: 'petHairLength',
              id: slugify(value),
              value,
            });
            if (parsedValue.success) {
              setPetAttribute(parsedValue.data);
            } else {
              showToast(parsedValue.error.message, 'error');
              logger({
                message: '[Select cat hair validation]',
                type: 'error',
                payload: {
                  error: parsedValue.error,
                },
              });
            }
          }}
          css={{
            '& .radio-text-content::first-letter': {
              textTransform: 'uppercase',
            },
          }}
        >
          {hairAttributes.map((a) => {
            return (
              <PetAttributeRadioButton
                key={a.value}
                value={a.value}
                label={a.label}
                desc={a.desc}
              />
            );
          })}
        </StyledRadioGroup>
      </StepSectionLayout>
    </Grid>
  );
}

type PetAttributeRadioButtonProps = {
  value: string;
  label: string;
  desc: string;
};

export function PetAttributeRadioButton({
  value,
  label,
  desc,
}: PetAttributeRadioButtonProps) {
  return (
    <Radio
      value={value}
      p="8px 16px"
      css={{
        '& .radio-text-content': {
          mr: '8px',
        },
      }}
    >
      <Grid gap="4px">
        <Text>{label}</Text>
        <Text textSize="body2" color="greyscale.60">
          {desc}
        </Text>
      </Grid>
    </Radio>
  );
}

export function CatLayout() {
  const [queryParams] = useSearchParams();
  const queryParamStep = queryParams.get('step');

  if (!queryParamStep) {
    return <BaseLayout />;
  }
  return <Steps step={Number(queryParamStep)} />;
}
