import chalk from 'chalk';

type Type = 'info' | 'success' | 'error' | 'warn' | '';

type Props = {
  message: string;
  payload?: unknown;
  type?: Type;
};

function getColor(type: Type) {
  switch (type) {
    default:
    case 'info': {
      return chalk.blueBright;
    }
    case 'error': {
      return chalk.redBright;
    }
    case 'warn': {
      return chalk.magenta;
    }
    case 'success': {
      return chalk.green;
    }
  }
}

function logger({ message, payload, type = 'info' }: Props) {
  const color = getColor(type);

  console.groupCollapsed(`${color(message)}`);
  if (payload) {
    console.log(payload);
  }
  console.groupEnd();
}
function logQueryError(query: string, errorMessage: string) {
  logger({
    message: `[Query]: ${query} \n[Error]: ${errorMessage}`,
    type: 'error',
  });
}

const pageLogger = {
  start: () => {
    console.time(chalk.blue('[Page queries duration (server)]'));
    return performance.now();
  },
  end: () => {
    console.timeEnd(chalk.blue('[Page queries duration (server)]'));
    return performance.now();
  },
  getDuration: (start: number, end: number) => {
    const tot = end - start;
    return tot < 1000 ? `${tot.toFixed(2)}ms` : `${(tot / 1000).toFixed(3)}s`;
  },
};

export { logger, pageLogger, logQueryError };
