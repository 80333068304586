import { Radio, StyledRadioGroup } from '@/atoms/Radio';
import { Text } from '@/atoms/Text';
import { InputDatePicker } from '@/organisms/InputDatePicker';
import { useNoResultsDialogStore } from '@/organisms/NoResultsDialog/NoResultsDialog';
import { StepSectionLayout } from '@/organisms/StepSectionLayout';
import { styled } from '@/panda/jsx/factory';
import { Flex } from '@/panda/jsx/flex';
import { Grid } from '@/panda/jsx/grid';
import { Box } from '@/panda/jsx/index';
import { booking } from '@/queries/booking';
import { GetSlotsQueryVariables, getSlots } from '@/queries/slots';
import {
  PetSizes,
  getPetHairLength,
  getPetSize,
  useAppStore,
} from '@/store/Store';
import { logger } from '@/utils/logger';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { NoDateResultBox } from './NoDateResultBox';

const FormWrapper = styled('form');

function LoadingSkeleton() {
  return (
    <Flex
      h="58px"
      borderRadius="8px"
      bg="white"
      alignItems="center"
      justifyContent="space-between"
      border="1px solid token(colors.primary.300)"
      p="16px"
    >
      <Flex w="60px" h="16px" borderRadius="4px" bg="primary.300" />
      <Flex w="20px" h="20px" borderRadius="50%" bg="primary.300" />
    </Flex>
  );
}

type GetSlotsProps = {
  key: string;
  variables?: GetSlotsQueryVariables;
  enabled?: boolean;
};
export function useGetSlotsQuery({ enabled, variables, key }: GetSlotsProps) {
  const query = useQuery({
    enabled,
    queryKey: [key, variables],
    gcTime: 1,
    queryFn: async () => {
      if (variables) {
        return await getSlots({ variables });
      }
      return null;
    },
  });

  return query;
}

export function Step4() {
  const handleOpenNoResultsDialog = useNoResultsDialogStore(
    (s) => s.handleOpen,
  );

  const navigateTo = useNavigate();
  const [isBooking, setIsBooking] = useState(false);

  const { setTotalPrice, setDate, setTimeSlot } = useAppStore((s) => ({
    setTotalPrice: s.setTotalPrice,
    setDate: s.setDate,
    setTimeSlot: s.setTimeSlot,
  }));

  const selectedDate = useAppStore((s) => s.date);
  const petCategory = useAppStore((s) => s.petCategory);
  const timeSlot = useAppStore((s) => s.timeSlot);
  const availableDates = useAppStore((s) => s.availableDates);
  const petAttrs = useAppStore((s) => ({
    petHairLength: getPetHairLength(s.petAttributes),
    petSize: getPetSize(s.petAttributes),
  }));
  const postalCode = useAppStore(
    (s) => s.contactInfo?.location?.components?.postalCode ?? '',
  );
  const serviceId = useAppStore((s) => s.activeService?.id ?? '');

  const variables: GetSlotsQueryVariables = {
    postalCode,
    serviceId,
    petHairLength: petAttrs.petHairLength?.value,
    petSize: petAttrs.petSize?.value,
    ...(selectedDate
      ? {
          date: dayjs(selectedDate).format('YYYY-MM-DD'),
        }
      : {}),
  };

  const { data, isLoading, status } = useGetSlotsQuery({
    variables,
    key: 'time-slots',
    enabled: !!selectedDate,
  });

  const activeDates = availableDates;
  const availabilities = data?.result?.success ? data.result.data.times : [];
  const totalPrice = data?.result?.success ? data.result.data.total ?? '' : '';
  const notAvailableDates = data?.result.success
    ? data.result.data.times.length === 0
    : false;

  useEffect(() => {
    if (status === 'success') {
      setTotalPrice(totalPrice);
    }
  }, [totalPrice, status]);

  const bookingData = useAppStore((s) => {
    return {
      petCategory: s.petCategory!,
      services: [s.activeService!.id],
      date: dayjs(s.date).format('YYYY-MM-DD'),
      startTime: s.timeSlot,
      petSize: getPetSize(s.petAttributes)?.value ?? PetSizes.Enum.one,
      petHairLength: getPetHairLength(s.petAttributes)!.value,
      email: s.contactInfo!.email,
      firstName: s.contactInfo!.firstName,
      lastName: s.contactInfo!.lastName,
      phoneNumber: s.contactInfo!.telephone,
      location: s.contactInfo!.location,
    };
  });

  return (
    <StepSectionLayout
      title="Le nostre disponibilità"
      gap="24px"
      position="relative"
      maxW={{
        md: '724px',
        lg: '800px',
      }}
      css={{
        '& .title-wrapper': {
          top: '40px',
          position: {
            base: 'static',
            md: 'sticky',
          },
        },
      }}
    >
      {isBooking && (
        <Flex
          position="absolute"
          top="-8px"
          bottom="-8px"
          left="-16px"
          right="-16px"
          zIndex="10"
          justifyContent="center"
          alignItems="center"
          bg="rgba(255,255,255,0.94)"
          borderRadius="8px"
        >
          <Box className="loader" />
        </Flex>
      )}
      <FormWrapper
        display="grid"
        id="booking-form"
        alignContent="start"
        alignItems="start"
        w="100%"
        onSubmit={async (e) => {
          e.preventDefault();

          setIsBooking(true);
          try {
            const res = await booking({ variables: bookingData });
            if (res.success) {
              navigateTo(`/${petCategory}/thank-you?orderId=${res.data.id}`);
            } else {
              handleOpenNoResultsDialog({
                renderWhatsappLink: false,
                title: 'Ooops! Qualcosa è andato storto.',
                content:
                  'C’è stato un problema durante la prenotazione. Per favore, riprova.',
              });
            }
          } catch (error) {
            logger({
              message: `[Booking]`,
              type: 'error',
              payload: error,
            });
          }
          setIsBooking(false);
        }}
        gap={{
          base: '24px',
          lg: '40px',
        }}
        gridTemplateColumns={{
          base: '1fr',
          md: '340px 360px',
          lg: '400px 360px',
        }}
      >
        <Flex
          top="100px"
          position={{
            md: 'sticky',
          }}
        >
          <InputDatePicker
            selectedDate={selectedDate}
            setSelectedDate={setDate}
            activeDates={activeDates}
          />
        </Flex>
        <Flex flexDir="column" flexShrink="0">
          {(!selectedDate || notAvailableDates) && !isLoading && (
            <NoDateResultBox
              step={petCategory}
              notAvailableDates={notAvailableDates}
            />
          )}
          {isLoading && (
            <Grid gap="24px">
              <Flex h="36px" alignItems="center">
                <Flex h="28px" w="140px" borderRadius="4px" bg="primary.300" />
              </Flex>
              <Grid gap="8px">
                <LoadingSkeleton />
                <LoadingSkeleton />
                <LoadingSkeleton />
                <LoadingSkeleton />
              </Grid>
            </Grid>
          )}
          {!isLoading &&
            selectedDate &&
            !notAvailableDates &&
            availabilities.length > 0 && (
              <Grid gap="24px" alignContent="start">
                <Text
                  font="serif"
                  fontSize="2rem"
                  lineHeight="3.6rem"
                  color="primary.900"
                  fontWeight="700"
                >
                  Scegli l'orario
                </Text>
                <StyledRadioGroup value={timeSlot} onChange={setTimeSlot}>
                  {availabilities.map((time) => {
                    return (
                      <Radio key={`radio-item-${time}`} value={time}>
                        {time}
                      </Radio>
                    );
                  })}
                </StyledRadioGroup>
              </Grid>
            )}
        </Flex>
      </FormWrapper>
    </StepSectionLayout>
  );
}
