import { fetchData, setQueryParamsToUrl } from '@/queries/fetch';
import { PetHairLengths, PetSizes } from '@/store/Store';
import { z } from 'zod';

type ResultSuccess = {
  success: true;
  data: {
    slots: { [key: string]: Slot[] };
    dates: string[];
    postalCode: string;
    serviceId: string;
    times: string[];
    total?: string;
  };
};

type ResultError = {
  success: false;
  error: {
    message: string;
  };
};

export type SlotsAPIResponse = {
  result: ResultSuccess | ResultError;
};

export type Slot = {
  date: string;
  startDateTime: string;
  endDateTime: string;
  providerId: string;
  providerName: string;
  startTime: string;
};

const dateSchema = z.string().refine((v) => {
  const [year, month, day] = v.split('-');
  return year.length === 4 && month.length === 2 && day.length === 2;
});

export type GetSlotsQueryVariables = {
  postalCode: string;
  serviceId: string;
  date?: z.infer<typeof dateSchema>;
  petHairLength?: z.infer<typeof PetHairLengths>;
  petSize?: z.infer<typeof PetSizes>;
};

type Props = {
  variables: GetSlotsQueryVariables;
};

// https://api.pelomatto.it/bookings/slots?postalCode=20121&serviceId=
export async function getSlots({ variables }: Props) {
  const url = new URL(`${import.meta.env.VITE_API_URL}/bookings/slots`);
  setQueryParamsToUrl(url, variables);
  return await fetchData<SlotsAPIResponse>({
    url,
    operationName: 'GetSlots',
    type: 'Query',
  });
}
