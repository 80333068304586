export function formatCurrency(
  value: number,
  opts: Intl.NumberFormatOptions = {},
) {
  const res = value.toLocaleString('it-IT', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    ...opts,
  });
  return `€${res.replace('€', '')}`;
}
