import { fetchData } from '@/queries/fetch';
import {
  LocationSchema,
  PetCategoriesSchema,
  PetHairLengths,
  PetSizes,
} from '@/store/Store';
import { z } from 'zod';

export type BookingResponse = {
  _id: string;
  userId: string;
  timeZone: string;
  date: Date;
  startTime: string;
  endTime: string;
  startDateTime: Date;
  endDateTime: Date;
  location: Location;
  status: string;
  statusConfirmedDate: Date;
  statusRequestedDate: Date;
  leadSource: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  pets: PetElement[];
  durationMM: number;
  total: string;
  assignedTo: string;
  assignedToUser: {
    createdAt: string;
    email: string;
    firstName: string;
    id: string;
    isProvider: boolean;
    lastName: string;
    phoneNumber: string;
  };
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  id: string;
  user: User;
  payments: string[];
  providersrefunds: string[];
  providersrefundsfiles: string[];
  petsRecords: PetsRecord[];
};

export type Location = {
  address: string;
  googlePlaceId: string;
  components: Components;
  coordinates: Coordinates;
};

export type Components = {
  route: string;
  streetNumber: string;
  city: string;
  province: string;
  provinceCode: string;
  region: string;
  postalCode: string;
  country: string;
  countryCode: string;
};

export type Coordinates = {
  lat: number;
  lng: number;
};

export type PetElement = {
  userId: string;
  petId: string;
  name: string;
  services: Service[];
  _id: string;
  pet: PetPet;
};

export type PetPet = {
  id: string;
  name: string;
  createdAt: Date;
  petCategory: string;
  petHairLength: string;
  petSize: string;
  firstService: boolean;
  isDead: boolean;
};

export type Service = {
  serviceId: string;
  price: string;
  listPrice: string;
  name: string;
  _id: string;
};

export type PetsRecord = {
  _id: string;
  userEmail: string;
  userId: string;
  name: string;
  petCategory: string;
  petHairLength: string;
  petSize: string;
  firstService: boolean;
  isDead: boolean;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
};

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  roles: string[];
  isProvider: boolean;
  techDetails: TechDetails;
  createdAt: Date;
};

export type TechDetails = {
  fitToPlead: boolean;
};

const BookingVariablesSchema = z.object({
  services: z.array(z.string()),
  petCategory: PetCategoriesSchema,
  date: z.string().refine((v) => {
    const [year, month, day] = v.split('-');
    return year.length === 4 && month.length === 2 && day.length === 2;
  }),
  startTime: z.string().refine((v) => {
    const [hours, minutes] = v.split(':');
    const numberHours = Number(hours);
    const numberMinutes = Number(minutes);
    return (
      hours.length === 2 &&
      numberHours >= 0 &&
      numberHours < 24 &&
      minutes.length === 2 &&
      numberMinutes >= 0 &&
      numberMinutes < 60
    );
  }),
  petSize: PetSizes,
  petHairLength: PetHairLengths,
  email: z.string().email().min(1),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  phoneNumber: z.string().min(1),
  location: LocationSchema,
});

type MutationResponse = {
  result: {
    success: boolean;
    data: {
      booking: BookingResponse;
    };
  };
};

type SuccessMutationResponse = {
  success: true;
  data: BookingResponse;
};

type FailedMutationResponse = {
  success: false;
  error: string;
};

type Props = {
  variables: z.infer<typeof BookingVariablesSchema>;
};

export async function booking({
  variables,
}: Props): Promise<SuccessMutationResponse | FailedMutationResponse> {
  const url = new URL(`${import.meta.env.VITE_API_URL}/bookings/website`);

  const validatedData = BookingVariablesSchema.safeParse(variables);

  if (validatedData.success) {
    const res = await fetchData<MutationResponse>({
      url,
      operationName: 'Booking',
      type: 'Mutation',
      opts: {
        method: 'POST',
        body: JSON.stringify(validatedData.data),
      },
    });

    return {
      success: true,
      data: res.result.data.booking,
    };
  }

  return {
    success: false,
    error: validatedData.error.message,
  };
}
