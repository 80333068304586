import { z } from 'zod';

import { fetchData } from '.';
import { LocationSchema } from '../store';

// list:[newsletter,out-of-area],
// email,
// location,
// firstName,
// lastName

const SignupNewsletterVariables = z.object({
  list: z.enum(['newsletter', 'out-of-area']),
  email: z.string().email().min(1),
  location: LocationSchema.nullable(),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
});

type NewsletterSignupResponse = {
  result: {
    success: boolean;
  };
};

type Props = {
  variables: z.infer<typeof SignupNewsletterVariables>;
};

export async function signupNewsletter({ variables }: Props) {
  const validatedData = SignupNewsletterVariables.safeParse(variables);
  if (validatedData.success) {
    const url = new URL(`${import.meta.env.VITE_API_URL}/subscribe`);

    const res = await fetchData<NewsletterSignupResponse>({
      url,
      type: 'Mutation',
      operationName: 'SignupNewsletter',
      opts: {
        method: 'POST',
        body: JSON.stringify(validatedData.data),
      },
    });

    return {
      success: res.result.success,
    };
  }

  return {
    success: false,
    error: validatedData.error.message,
  };
}
