import { BoxProps, styled } from '@/panda/jsx';
import { TextRecipeVariant, textRecipe } from '@/panda/recipes/text-recipe';
import { ConditionalValue } from '@/panda/types/conditions';
import cs from 'classnames';
import { ComponentProps, useMemo } from 'react';

type RecipeProps = {
  textSize?: ConditionalValue<TextRecipeVariant['textSize']>;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p';
  font?: ConditionalValue<TextRecipeVariant['font']>;
  variant?: ConditionalValue<TextRecipeVariant['variant']>;
};

export type Props = Omit<
  ComponentProps<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p'>,
  'color'
> &
  BoxProps &
  RecipeProps;

export function Text({
  textSize,
  children,
  font,
  variant,
  ...rest
}: Omit<Props, 'ref'>) {
  if (!rest.as) {
    rest.as = 'span';
  }

  const { as: _ } = rest;

  const Element = useMemo(() => styled(rest.as ?? 'span'), [rest.as]);

  return (
    <Element
      {...rest}
      className={cs([textRecipe({ textSize, font, variant }), rest.className])}
    >
      {children}
    </Element>
  );
}
