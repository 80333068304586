import { dog } from '@/data/data.json';
import { showToast } from '@/molecoles/Toast';
import { StepSectionLayout } from '@/organisms/StepSectionLayout';
import { styled } from '@/panda/jsx/factory';
import { Grid } from '@/panda/jsx/grid';
import { PetAttributeRadioButton } from '@/routes/cat/CatLayout';
import { PetHairAttributeSchema, PetSizeAttributeSchema } from '@/store/Store';
import { useAppStore } from '@/store/index';
import { logger } from '@/utils/logger';
import { RadioGroup } from 'react-aria-components';
import { useSearchParams } from 'react-router-dom';
import slugify from 'slugify';
import { Steps } from 'src/components/templates/Steps';

const StyledRadioGroup = styled(RadioGroup);

function BaseDogLayout() {
  const petAttributes = useAppStore((s) => s.petAttributes ?? []);
  const setPetAttribute = useAppStore((s) => s.setPetAttribute);

  const hairAttributes = dog.attrs.petHairLength;
  const sizeAttributes = dog.attrs.petSize;

  const initialHairValue = petAttributes.find(
    (a) => a.type === 'petHairLength',
  )?.value;
  const initialSizeValue = petAttributes.find(
    (a) => a.type === 'petSize',
  )?.value;

  return (
    <Grid
      alignContent="start"
      w="100%"
      maxW="600px"
      gap={{
        base: '40px',
        md: '64px',
      }}
    >
      <StepSectionLayout title="Che tipo di pelo ha il tuo cane?">
        <StyledRadioGroup
          display="grid"
          gap="8px"
          value={initialHairValue}
          onChange={(value) => {
            const parsedValue = PetHairAttributeSchema.safeParse({
              type: 'petHairLength',
              id: slugify(value),
              value,
            });
            if (parsedValue.success) {
              setPetAttribute(parsedValue.data);
            } else {
              showToast(parsedValue.error.message, 'error');
              logger({
                message: '[Select dog hair validation]',
                type: 'error',
                payload: {
                  error: parsedValue.error,
                },
              });
            }
          }}
          css={{
            '& .radio-text-content::first-letter': {
              textTransform: 'uppercase',
            },
          }}
        >
          {hairAttributes.map((a) => {
            return (
              <PetAttributeRadioButton
                key={a.value}
                value={a.value}
                label={a.label}
                desc={a.desc}
              />
            );
          })}
        </StyledRadioGroup>
      </StepSectionLayout>
      <StepSectionLayout title="Di che taglia è il tuo cane?">
        <StyledRadioGroup
          display="grid"
          value={initialSizeValue}
          gap="8px"
          onChange={(value) => {
            const parsedValue = PetSizeAttributeSchema.safeParse({
              type: 'petSize',
              id: slugify(value),
              value,
            });
            if (parsedValue.success) {
              setPetAttribute(parsedValue.data);
            } else {
              showToast(parsedValue.error.message, 'error');
              logger({
                message: '[Select dog size validation]',
                type: 'error',
                payload: {
                  error: parsedValue.error,
                },
              });
            }
          }}
          css={{
            '& .radio-text-content::first-letter': {
              textTransform: 'uppercase',
            },
          }}
        >
          {sizeAttributes.map((a) => {
            return (
              <PetAttributeRadioButton
                key={a.value}
                value={a.value}
                label={a.label}
                desc={a.desc}
              />
            );
          })}
        </StyledRadioGroup>
      </StepSectionLayout>
    </Grid>
  );
}

export function DogLayout() {
  const [queryParams] = useSearchParams();
  const queryParamStep = queryParams.get('step');

  if (!queryParamStep) {
    return <BaseDogLayout />;
  }
  return <Steps step={Number(queryParamStep)} />;
}
