import { createContext, useContext } from 'react';
import { z } from 'zod';
import { create, useStore } from 'zustand';

import { logger } from '../utils';

function getPetHairLength(attrs: z.infer<typeof PetAttributes>) {
  const result = PetHairAttributeSchema.safeParse(
    attrs.find((a) => a.type === 'petHairLength'),
  );
  if (result.success) {
    return result.data;
  }
  return undefined;
}
function getPetSize(attrs: z.infer<typeof PetAttributes>) {
  const result = PetSizeAttributeSchema.safeParse(
    attrs.find((a) => a.type === 'petSize'),
  );
  if (result.success) {
    return result.data;
  }
  return undefined;
}

const PetHairLengths = z.enum(['short', 'medium', 'long']);
const PetSizes = z.enum(['toy', 'small', 'medium', 'one']);

const Step = z.number().optional();
const Version = z.string().refine((value) => value === '1');
const PetCategoriesSchema = z.enum(['dog', 'cat']);
const PetCategories = PetCategoriesSchema.nullable();
const ServiceSchema = z.object({
  name: z.string().min(1),
  id: z.string().min(1),
  description: z.string(),
  minPrice: z.string().min(1),
});
const ActiveService = ServiceSchema.nullable();
const PetHairAttributeSchema = z.object({
  id: z.string().min(1),
  type: z.literal('petHairLength'),
  value: PetHairLengths,
});
const PetSizeAttributeSchema = z.object({
  id: z.string().min(1),
  type: z.literal('petSize'),
  value: PetSizes,
});
const PetAttributes = z.array(
  PetHairAttributeSchema.or(PetSizeAttributeSchema),
);
const TimeSlot = z.string();
const Date = z.coerce.date().optional();
const LocationSchema = z.object({
  address: z.string().min(1, '*Richiesto'),
  googlePlaceId: z.string().min(1),
  components: z.object({
    route: z.string().min(1),
    streetNumber: z.string().min(1, '*Richiesto'),
    city: z.string().min(1),
    province: z.string().min(1),
    provinceCode: z.string().min(1),
    region: z.string().min(1),
    postalCode: z.string().min(1, '*Richiesto'),
    country: z.string().min(1),
    countryCode: z.string().min(1),
  }),
  coordinates: z.object({
    lat: z.number(),
    lng: z.number(),
  }),
});
const ContactInfoSchema = z.object({
  firstName: z.string().min(1, '*Richiesto'),
  lastName: z.string().min(1, '*Richiesto'),
  email: z.string().min(1, '*Richiesto').email('Il formato non è valido'),
  telephone: z.string().min(1, '*Richiesto'),
  location: LocationSchema,
});
export const ContactInfo = ContactInfoSchema.optional();
const AvailableDates = z.array(z.string());
const TotalPrice = z.string();

const storeSchema = z
  .object({
    totalPrice: TotalPrice.optional(),
    version: Version,
    step: Step,
    petCategory: PetCategories,
    activeService: ActiveService,
    timeSlot: TimeSlot,
    petAttributes: PetAttributes,
    contactInfo: ContactInfo,
    date: Date,
    availableDates: AvailableDates.optional(),
  })
  .strict();

export type AppState = z.infer<typeof storeSchema>;

type StoreState = AppState & {
  setTotalPrice(totalPrice: string): void;
  setPetCategory(step: AppState['petCategory']): void;
  setTimeSlot(timeSlot: string): void;
  setPetAttribute(prop: NonNullable<AppState['petAttributes']>[number]): void;
  setActiveService(prop: z.infer<typeof ActiveService>): void;
  updateStore(data: Partial<AppState>): void;
  setContactInfo(data: NonNullable<AppState['contactInfo']>): void;
  setDate(date: Date | undefined): void;
  setAvailableDates(dates: AppState['availableDates']): void;
  resetStore(): void;
};

const defaultInitialState = {
  petCategory: null,
  petAttributes: [],
  activeService: null,
  timeSlot: '',
  totalPrice: '',
  contactInfo: undefined,
  availableDates: [],
  step: undefined,
  date: undefined,
};

function initializeStore(initialState: Partial<AppState>) {
  return create<StoreState>((set) => {
    return {
      version: '1',
      ...defaultInitialState,
      ...initialState,
      setTotalPrice: (totalPrice) => set({ totalPrice }),
      setAvailableDates: (dates) => set({ availableDates: dates }),
      setContactInfo: (contactInfo) => {
        set({ contactInfo });
      },
      setPetCategory: (newPetCaegory) => {
        set({
          petCategory: newPetCaegory,
          petAttributes: [],
          activeService: null,
          timeSlot: '',
        });
      },
      setTimeSlot: (timeSlot) => {
        set({
          timeSlot,
        });
      },
      setActiveService: (newService) => {
        set({ activeService: newService });
      },
      setPetAttribute: (newAttribute) => {
        set((prev) => {
          const attrs = [...(prev.petAttributes ?? [])];
          const currentAttrIndex = attrs.findIndex(
            (i) => i.type === newAttribute.type,
          );

          if (currentAttrIndex === -1) {
            attrs.push(newAttribute);
          } else {
            attrs.splice(currentAttrIndex, 1, newAttribute);
          }

          return {
            petAttributes: attrs,
          };
        });
      },
      setDate: (date) => set({ date, timeSlot: '' }),
      updateStore: (data) => set(data),
      resetStore: () => set(defaultInitialState),
    };
  });
}
type Store = ReturnType<typeof initializeStore>;

const AppStoreContext = createContext<Store | null>(null);

type StoreCb<T> = (props: StoreState) => T;

function useAppStore<T>(cb: StoreCb<T>): T {
  const context = useContext(AppStoreContext);
  if (!context) {
    throw new Error('Cartomanti store must be used within the provider!');
  }
  return useStore(context, cb);
}

const __LOCAL_STORAGE_KEY__ = '__PELOMATTO_STORE__';

function validateInternalStoreDataInput(data: unknown) {
  const validate = storeSchema.safeParse(data);
  if (validate.success) {
    logger({
      message: '[Validate input app store data]: Data is valid',
      type: 'success',
      payload: validate,
    });
    return validate.data;
  }
  if (!validate.success) {
    logger({
      message: '[Validate input app store data]: Data is not valid',
      type: 'warn',
      payload: validate.error,
    });
  }
  return null;
}
function saveDataToLocalStorage(
  data: AppState & { version: string; step?: number },
) {
  const validation = storeSchema.safeParse(data);

  if (validation.success) {
    localStorage.setItem(
      __LOCAL_STORAGE_KEY__,
      JSON.stringify(validation.data),
    );
    logger({
      message: '[Save data to localStorage]: ok',
      type: 'success',
      payload: {
        rawData: validation.data,
        stringifyData: JSON.stringify(validation.data),
      },
    });
  } else {
    logger({
      message: '[Save data to localStorage]: error',
      type: 'error',
      payload: validation.error,
    });
  }
}
function resetLocalStorage() {
  localStorage.removeItem(__LOCAL_STORAGE_KEY__);
}
function getDataFromLocalStorage() {
  const data = localStorage.getItem(__LOCAL_STORAGE_KEY__);
  const validation = data ? storeSchema.safeParse(JSON.parse(data)) : null;

  if (validation?.success) {
    return validation.data;
  }
  if (validation?.error) {
    logger({
      message: '[Get data from localStorage]',
      type: 'error',
      payload: validation,
    });
  }

  return null;
}

export {
  useAppStore,
  initializeStore,
  AppStoreContext,
  storeSchema,
  saveDataToLocalStorage,
  getDataFromLocalStorage,
  validateInternalStoreDataInput,
  ContactInfoSchema,
  PetHairAttributeSchema,
  PetSizeAttributeSchema,
  PetCategoriesSchema,
  PetAttributes,
  PetHairLengths,
  PetSizes,
  ServiceSchema,
  LocationSchema,
  getPetHairLength,
  getPetSize,
  resetLocalStorage,
};
