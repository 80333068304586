import { Box } from '@/panda/jsx/box';
import { styled } from '@/panda/jsx/factory';
import { Flex } from '@/panda/jsx/flex';
import { TextRecipeVariant, textRecipe } from '@/panda/recipes/text-recipe';
import { ConditionalValue } from '@/panda/types/conditions';
import cs from 'classnames';
import { ComponentProps, ReactNode } from 'react';
import { Radio as RARadio, RadioGroup } from 'react-aria-components';

const StyledRadio = styled(RARadio);
const StyledRadioGroup = styled(RadioGroup, {
  base: {
    display: 'grid',
    gap: '8px',
  },
});

type Props = Omit<ComponentProps<typeof StyledRadio>, 'children'> & {
  textSize?: ConditionalValue<TextRecipeVariant['textSize']>;
  children: ReactNode;
};

function Radio({ children, textSize, ...rest }: Props) {
  return (
    <StyledRadio
      display="flex"
      alignItems="center"
      p="16px"
      borderRadius="8px"
      bg="white"
      border="1px solid token(colors.primary.300)"
      cursor="pointer"
      transition="border-color 140ms ease-out"
      position="relative"
      {...rest}
      className={cs(
        cs(textRecipe({ textSize }), 'radio-button', rest.className),
      )}
      _before={{
        content: "''",
        display: 'block',
        position: 'absolute',
        top: '0',
        left: '0',
        w: '100%',
        h: '100%',
        borderRadius: 'inherit',
        pointerEvents: 'none',
        boxShadow: '0px 0px 0px 2px token(colors.primary.500)',
        opacity: '0',
        transition: 'opacity 140ms ease-out',
      }}
      _after={{
        content: "''",
        display: 'block',
        position: 'absolute',
        top: '0',
        left: '0',
        w: '100%',
        h: '100%',
        borderRadius: 'inherit',
        pointerEvents: 'none',
        boxShadow: '0px 4px 15px rgba(0,0,0,0.2)',
        opacity: '0',
        transition: 'opacity 140ms ease-out',
      }}
      css={{
        '&[data-selected=true]': {
          borderColor: 'primary.500',
        },
        '&[data-selected=true]::after, &[data-selected=true]::before': {
          opacity: '1',
        },
        ...(rest.css ?? {}),
      }}
      _hover={{
        borderColor: 'primary.500',
      }}
    >
      <>
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          borderRadius="inherit"
          opacity="0"
          pointerEvents="none"
          transition="opacity 400ms ease-out"
          zIndex="1"
          css={{
            background: 'linear-gradient(270deg, #EAF1F7 0%, #FFFFFF 42.62%)',
            '[data-selected=true] &': {
              opacity: '1',
            },
          }}
        />
        <Box zIndex="2" className="radio-text-content">
          {children}
        </Box>
        <Flex
          className="radio-box"
          flexShrink="0"
          zIndex="2"
          ml="auto"
          position="relative"
          justifyContent="center"
          alignItems="center"
          w="20px"
          h="20px"
          borderRadius="50%"
          border="2px solid token(colors.primary.300)"
          transition="border-color 140ms ease-out"
          css={{
            '[data-selected=true] &': {
              borderColor: 'primary.500',
            },
          }}
        >
          <Flex
            position="absolute"
            bg="primary.500"
            transition="opacity 140ms ease-out, transform 140ms ease-out"
            w="10px"
            h="10px"
            borderRadius="50%"
            opacity="0"
            transform="scale(0.64)"
            css={{
              '[data-selected=true] &': {
                opacity: '1',
                transform: 'scale(1)',
              },
            }}
          />
        </Flex>
      </>
    </StyledRadio>
  );
}

export { Radio, StyledRadioGroup };
