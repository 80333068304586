import { useSpring } from '@react-spring/web';
import { useEffect } from 'react';

export function useSpringScroll() {
  const [, set] = useSpring(() => ({
    y: 0,
    config: {
      tension: 240,
      friction: 24,
      mass: 0.48,
    },
  }));

  useEffect(() => {
    function stopSpringScroll() {
      set.stop();
    }

    window.addEventListener('wheel', stopSpringScroll);
    return () => {
      window.removeEventListener('wheel', stopSpringScroll);
    };
  }, []);

  return {
    scrollToElement: (
      element: HTMLElement,
      gap = 0,
      _container?: HTMLElement,
    ) => {
      function getScrollTop() {
        if (_container) {
          return _container.scrollTop;
        }
        return window.scrollY;
      }
      function getInnerHeight() {
        if (_container) {
          return _container.clientHeight;
        }
        return window.innerHeight;
      }
      function getScrollHeight() {
        if (_container) {
          return _container.scrollHeight;
        }
        return document.documentElement.scrollHeight;
      }

      if (!element) {
        console.warn(`Element  doesn't exist`);
        return;
      }

      let bannerGap = 0;

      const headerBanner =
        document.querySelector<HTMLElement>('.header-banner');
      if (headerBanner) {
        bannerGap = headerBanner.offsetHeight;
      }

      const scrollToTotal =
        getScrollTop() + element.getBoundingClientRect().top - gap - bannerGap;

      const availableScrollSpace = getScrollHeight() - getInnerHeight();

      set.start({
        from: {
          y: getScrollTop(),
        },
        to: {
          y:
            scrollToTotal > availableScrollSpace
              ? availableScrollSpace
              : scrollToTotal,
        },
        onChange: (props) => {
          (_container ?? window).scroll(0, props.value.y);
        },
      });
    },
  };
}
