import LogoSvg from '@/icons/logo.svg';
import { css } from '@/panda/css/css';
import { styled } from '@/panda/jsx/factory';

import { StepProgressBar } from './StepProgressBar';
import { TotalPrice } from './TotalPrice';

export function Header() {
  return (
    <styled.header
      h="56px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p="16px"
      bg="white"
      boxShadow="0px 4px 15px rgba(0,0,0,0.24)"
      position="sticky"
      top="0"
      w="100%"
      zIndex="50"
    >
      <StepProgressBar />
      <LogoSvg
        className={css({
          maxH: '24px',
          w: 'auto',
          h: '100%',
        })}
      />
      <TotalPrice />
    </styled.header>
  );
}
