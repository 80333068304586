import { Text } from '@/atoms/Text';
import CheckSvg from '@/icons/check.svg';
import bgImage from '@/images/bg.png';
import { css } from '@/panda/css/css';
import { styled } from '@/panda/jsx/factory';
import { Grid } from '@/panda/jsx/grid';
import { useAppStore } from '@/store/Store';
import { formatCurrency } from '@/utils/formatCurrency';

export function Step2() {
  const activeService = useAppStore((s) => s.activeService);

  const includes = (activeService?.description ?? '')
    .split('\n')
    .filter(Boolean);

  return (
    <Grid w="100%" gap="16px" alignContent="start" maxW="600px" mx="auto">
      <Grid
        gap="16px"
        p="24px 16px"
        position="relative"
        overflow="hidden"
        borderRadius="8px"
      >
        <img
          src={bgImage}
          title=""
          alt=""
          className={css({
            position: 'absolute',
            top: '0',
            left: '0',
            w: '100%',
            h: '100%',
            borderRadius: '8px',
          })}
        />
        <Grid gap="4px" zIndex="1">
          <Text
            as="h1"
            color="white"
            fontSize="3.2rem"
            textSize="pageTitle"
            font="serif"
            fontWeight="600"
          >
            {activeService?.name}
          </Text>
          {activeService?.minPrice && (
            <Text textSize="headline4" color="accent" fontWeight="400">
              €{formatCurrency(Number(activeService.minPrice)).replace('€', '')}
            </Text>
          )}
        </Grid>
        <Text textSize="body2" color="primary.50" zIndex="1">
          Metodi di pagamento accettati: bancomat, carta di debito e carta di
          credito.
        </Text>
      </Grid>
      <Grid bg="white" borderRadius="8px" p="24px">
        <Text
          color="primary.900"
          font="serif"
          fontSize="2rem"
          lineHeight="3.6rem"
          fontWeight="700"
        >
          Include:
        </Text>
        {includes.length > 0 && (
          <styled.ul display="grid" gap="8px">
            {includes.map((i) => {
              return (
                <styled.li key={i} display="flex" alignItems="center">
                  <CheckSvg
                    className={css({
                      mb: 'auto',
                    })}
                  />
                  <Text ml="16px" textSize="body2" color="#000">
                    {i}
                  </Text>
                </styled.li>
              );
            })}
          </styled.ul>
        )}
      </Grid>
    </Grid>
  );
}
