import { Text } from '@/atoms/Text';
import { Grid, GridProps } from '@/panda/jsx/grid';
import { ReactNode } from 'react';

type Props = {
  title: string;
  content?: string;
  children: ReactNode;
} & GridProps;

export function StepSectionLayout({
  title,
  content,
  children,
  ...rest
}: Props) {
  return (
    <Grid
      gap="40px"
      w="100%"
      alignContent="start"
      maxW="600px"
      mx="auto"
      {...rest}
    >
      <Grid gap="16px" w="100%" className="title-wrapper">
        <Text as="h1" variant="pageTitle">
          {title}
        </Text>
        {content && (
          <Text
            as="p"
            textSize="body2"
            whiteSpace="pre-wrap"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        )}
      </Grid>
      {children}
    </Grid>
  );
}
