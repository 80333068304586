import {
  resetLocalStorage,
  saveDataToLocalStorage,
  useAppStore,
} from '@/store/Store';
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export function UpdateStore() {
  const [queryParams] = useSearchParams();
  const { pathname } = useLocation();
  const queryParamStep = queryParams.get('step');

  const { updateStore, resetStore } = useAppStore((s) => ({
    updateStore: s.updateStore,
    resetStore: s.resetStore,
  }));
  const state = useAppStore((s) => ({
    petCategory: s.petCategory,
    activeService: s.activeService,
    petAttributes: s.petAttributes,
    timeSlot: s.timeSlot,
    contactInfo: s.contactInfo,
    date: s.date,
  }));

  const step = queryParamStep ? Number(queryParamStep) : undefined;

  /**
   * Listen to any url change (when user navigates back/forward)
   */
  useEffect(() => {
    if (pathname.includes('/thank-you')) {
      resetStore();
      resetLocalStorage();
      return;
    }

    updateStore({ step });
    if (state.petCategory) {
      saveDataToLocalStorage({
        ...state,
        version: '1',
        step,
      });
    }
  }, [pathname, step]);

  return null;
}
