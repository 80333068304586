import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const buttonRecipeFn = /* @__PURE__ */ createRecipe('button', {
  "textSize": "body1",
  "variant": "primary"
}, [])

const buttonRecipeVariantMap = {
  "textSize": [
    "headline1",
    "headline2",
    "headline3",
    "headline4",
    "headline5",
    "headline6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "button",
    "button-large",
    "caption",
    "overline",
    "pageTitle"
  ],
  "variant": [
    "none",
    "primary",
    "outlined"
  ]
}

const buttonRecipeVariantKeys = Object.keys(buttonRecipeVariantMap)

export const buttonRecipe = /* @__PURE__ */ Object.assign(memo(buttonRecipeFn), {
  __recipe__: true,
  __name__: 'buttonRecipe',
  raw: (props) => props,
  variantKeys: buttonRecipeVariantKeys,
  variantMap: buttonRecipeVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, buttonRecipeVariantKeys)
  },
})