import { Button } from '@/atoms/Button';
import { Link } from '@/atoms/Link';
import { Text } from '@/atoms/Text';
import CalendarSvg from '@/icons/calendar.svg';
import CurrencySvg from '@/icons/currency.svg';
import FaceSvg from '@/icons/face.svg';
import ServiceSvg from '@/icons/service.svg';
import TimeSvg from '@/icons/time.svg';
import { showToast } from '@/molecoles/Toast';
import { useNewsletterSignupDialogStore } from '@/organisms/NewsletterSignupDialog';
import { StepSectionLayout } from '@/organisms/StepSectionLayout';
import { Flex, FlexProps } from '@/panda/jsx/flex';
import { Grid } from '@/panda/jsx/grid';
import { Box } from '@/panda/jsx/index';
import { BookingResponse } from '@/queries/booking';
import { getBooking } from '@/queries/getBooking';
import { signupNewsletter } from '@/queries/signupNewsletter';
import { formatCurrency } from '@/utils/formatCurrency';
import { logger } from '@/utils/logger';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ReactNode, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function ItemLoadingSkeleton({
  withIcon = true,
  ...props
}: FlexProps & { withIcon?: boolean }) {
  return (
    <Flex alignItems="center">
      {withIcon && (
        <Flex
          w="24px"
          h="24px"
          borderRadius="4px"
          bg="primary.300"
          flexShrink="0"
          mr="16px"
        />
      )}
      <Flex w="160px" h="20px" borderRadius="4px" bg="primary.300" {...props} />
    </Flex>
  );
}

function LoadingSkeleton1() {
  return (
    <Grid
      gap={{
        base: '8px',
        lg: '12px',
      }}
    >
      <Flex w="200px" h="24px" bg="primary.300" borderRadius="4px" />
      <ItemLoadingSkeleton w="180px" />
      <ItemLoadingSkeleton w="120px" />
      <ItemLoadingSkeleton w="167px" />
      <ItemLoadingSkeleton w="95px" />
      <ItemLoadingSkeleton w="95px" />
    </Grid>
  );
}
function LoadingSkeleton2() {
  return (
    <Grid gap="8px">
      <Flex w="200px" h="24px" bg="primary.300" borderRadius="4px" />
      <ItemLoadingSkeleton w="180px" withIcon={false} />
      <ItemLoadingSkeleton w="120px" withIcon={false} />
      <ItemLoadingSkeleton w="167px" withIcon={false} />
      <ItemLoadingSkeleton w="95px" withIcon={false} />
    </Grid>
  );
}

type ItemProps = {
  icon: ReactNode;
  text: string;
};

function Item({ icon, text }: ItemProps) {
  return (
    <Flex alignItems="center">
      <Flex flexShrink="0" w="24px" h="24px" mr="16px">
        {icon}
      </Flex>
      <Text textSize="body2" color="greyscale.87">
        {text}
      </Text>
    </Flex>
  );
}
function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

type BookingContentProps = {
  data: BookingResponse;
};

function BookingContent({ data }: BookingContentProps) {
  const date = dayjs(data.date);
  const date1 = date.format('dddd');
  const date2 = date.format('DD');
  const date3 = date.format('MMMM');

  const formattedDate = `${capitalizeFirstLetter(date1)} ${date2} ${capitalizeFirstLetter(date3)}`;
  const name = data.assignedToUser.firstName;
  const totalPrice = `${formatCurrency(Number(data.total))} (Da pagare a fine prestazione)`;
  const petService = data.pets[0].services[0].name;

  return (
    <>
      <Grid
        gap={{
          base: '8px',
          lg: '12px',
        }}
      >
        <Text
          color="primary.900"
          font="serif"
          textSize="headline6"
          fontWeight="700"
        >
          La tua prenotazione
        </Text>
        <Item icon={<CalendarSvg />} text={formattedDate} />
        <Item icon={<TimeSvg />} text={data.startTime} />
        <Item icon={<FaceSvg />} text={name} />
        <Item icon={<ServiceSvg />} text={petService} />
        <Item icon={<CurrencySvg />} text={totalPrice} />
      </Grid>
      <Grid gap="8px">
        <Text
          color="primary.900"
          font="serif"
          textSize="headline6"
          fontWeight="700"
        >
          I tuoi dati
        </Text>
        <Text textSize="body2" color="greyscale.87">
          {data.user.firstName} {data.user.lastName}
        </Text>
        <Text textSize="body2" color="greyscale.87">
          +39 {data.user.phoneNumber}
        </Text>
        <Text textSize="body2" color="greyscale.87">
          {data.user.email}
        </Text>
        <Text textSize="body2" color="greyscale.87">
          {data.location.address}
        </Text>
      </Grid>
    </>
  );
}

export function ThankYouPage() {
  const [isSubscribingToNewsletter, setIsSubscribingToNewsletter] =
    useState(false);
  const [userIsSubscribedToNewsletter, setUserIsSubscribedToNewsletter] =
    useState(false);
  const handleOpenNewsletterSignup = useNewsletterSignupDialogStore(
    (s) => s.handleOpen,
  );
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  const { data, isLoading } = useQuery({
    enabled: !!orderId,
    queryKey: ['get-booking', orderId],
    queryFn: async () => {
      return await getBooking({
        variables: {
          orderId: orderId!,
        },
      });
    },
  });
  const bookingData = data?.result.success ? data.result.data.booking : null;

  return (
    <StepSectionLayout
      position="relative"
      title="Grazie della richiesta!"
      content={`Un nostro operatore la contatterà entro 48 ore per confermare la sua prenotazione.`}
      css={{
        gap: '24px',
      }}
    >
      {isSubscribingToNewsletter && (
        <Flex
          position="absolute"
          top="-8px"
          bottom="-8px"
          left="-16px"
          right="-16px"
          zIndex="10"
          justifyContent="center"
          alignItems="center"
          bg="rgba(255,255,255,0.94)"
          borderRadius="8px"
        >
          <Box className="loader" />
        </Flex>
      )}
      <Grid gap="16px" alignContent="start">
        <Grid gap="16px" w="100%" justifyItems="center">
          <Grid
            gap="24px"
            borderRadius="8px"
            bg="white"
            w="100%"
            p={{
              base: '16px',
              lg: '24px',
            }}
          >
            {isLoading && (
              <>
                <LoadingSkeleton1 />
                <LoadingSkeleton2 />
              </>
            )}
            {!isLoading && bookingData && <BookingContent data={bookingData} />}
          </Grid>
        </Grid>
        <Grid gap="16px">
          <Text textSize="body2">
            Iscriviti alla newsletter e rimani aggiornato sulle novità
            Pelomatto!
          </Text>
          <Button
            disabled={userIsSubscribedToNewsletter}
            onClick={async () => {
              setIsSubscribingToNewsletter(true);
              try {
                const res = await signupNewsletter({
                  variables: {
                    list: 'newsletter',
                    firstName: bookingData?.user.firstName ?? '',
                    lastName: bookingData?.user?.lastName ?? '',
                    location: bookingData?.location ?? null,
                    email: bookingData?.user?.email ?? '',
                  },
                });

                if (res.success) {
                  setUserIsSubscribedToNewsletter(true);
                  handleOpenNewsletterSignup('thank-you-page');
                } else {
                  showToast(
                    res.error ?? 'Oooops! Si è verificato un errore, riprova!',
                    'error',
                  );
                }
              } catch (error) {
                logger({
                  message: '[Signup newsletter]',
                  type: 'error',
                  payload: error,
                });
              }
              setIsSubscribingToNewsletter(false);
            }}
          >
            ISCRIVIMI ALLA NEWSLETTER
          </Button>
          <Link
            variant="none"
            to={import.meta.env.VITE_PRIVACY_POLICY_URL}
            target="_blank"
            textDecoration="underline"
            textSize="body2"
            fontWeight="400"
          >
            Privacy policy
          </Link>
        </Grid>
      </Grid>
    </StepSectionLayout>
  );
}
