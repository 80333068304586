import { css } from '@/panda/css';
import { BoxProps, styled } from '@/panda/jsx';
import { UseTransitionProps, animated, useTransition } from '@react-spring/web';
import cs from 'classnames';
import { ReactNode } from 'react';
import { Modal, ModalOverlay, Dialog as RADialog } from 'react-aria-components';

const AnimatedOverlay = animated(ModalOverlay);

const StyledModal = styled(Modal);

type Props = {
  isOpen: boolean;
  children: ReactNode;
  springConfig?: UseTransitionProps;
  dialogClassName?: string;
  containerClassName?: string;
  onClose(): void;
} & BoxProps;

export function Dialog({
  isOpen,
  onClose,
  children,
  dialogClassName,
  containerClassName,
  springConfig = {
    from: {
      opacity: 0,
      config: {
        tension: 240,
      },
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
      config: {
        tension: 580,
      },
    },
  },
  ...rest
}: Props) {
  const transition = useTransition(isOpen, springConfig);

  return transition((style, item) => {
    if (!item) return null;
    return (
      <AnimatedOverlay
        style={style}
        isOpen
        isDismissable
        className={cs(
          containerClassName,
          css({
            position: 'fixed',
            outline: 'none',
            top: '0',
            left: '0',
            w: '100%',
            h: '100%',
            display: 'flex',
            bg: 'greyscale.25',
            zIndex: '1000',
            pointerEvents: isOpen ? 'auto' : 'none',
            p: {
              base: '16px',
              lg: '24px',
            },
            '& .react-aria-Dialog': {
              outline: 'none!',
            },
          }),
        )}
        onOpenChange={(v) => {
          if (!v) {
            onClose();
          }
        }}
      >
        <StyledModal
          bg="white"
          p="40px"
          boxShadow="0px 13px 14.399999618530273px 0px #00000040"
          borderRadius="8px"
          w="100%"
          maxW="560px"
          maxH="100%"
          overflowY="auto"
          outline="none"
          m="auto"
          {...rest}
        >
          <RADialog className={dialogClassName}>{children}</RADialog>
        </StyledModal>
      </AnimatedOverlay>
    );
  });
}
