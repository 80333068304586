import { Button } from '@/atoms/Button';
import { Link } from '@/atoms/Link';
import { Text } from '@/atoms/Text';
import CatSvg from '@/icons/cat.svg';
import DogSvg from '@/icons/dog.svg';
import ProhibitSvg from '@/icons/prohibit.svg';
import { Dialog } from '@/molecoles';
import { css } from '@/panda/css/css';
import { Flex } from '@/panda/jsx/flex';
import { Grid } from '@/panda/jsx/grid';
import { useAppStore } from '@/store/Store';
import { create } from 'zustand';

type OpenProps = {
  title?: string;
  content?: string;
  renderWhatsappLink?: boolean;
};

type Store = {
  isOpen: boolean;
  title: string;
  content: string;
  renderWhatsappLink: boolean;
  handleClose(): void;
  handleOpen(props?: Partial<OpenProps>): void;
};

export const useNoResultsDialogStore = create<Store>((set) => {
  return {
    isOpen: false,
    title: 'Ooops! Sembra che non ci sia nessun risultato.',
    content: 'Riprova o clicca qui sotto per contattare l’assistenza!',
    renderWhatsappLink: true,
    handleClose: () => set({ isOpen: false }),
    handleOpen: (
      props = {
        title: 'Ooops! Sembra che non ci sia nessun risultato.',
        content: 'Riprova o clicca qui sotto per contattare l’assistenza!',
        renderWhatsappLink: true,
      },
    ) => set({ isOpen: true, ...props }),
  };
});

export function NoResultsDialog() {
  const { isOpen, handleClose, title, renderWhatsappLink, content } =
    useNoResultsDialogStore();
  const petCategory = useAppStore((s) => s.petCategory);

  return (
    <Dialog isOpen={isOpen} onClose={handleClose} maxW="400px">
      <Grid
        alignContent="start"
        gap="24px"
        justifyContent="center"
        justifyItems="center"
      >
        <Flex
          position="relative"
          alignItems="center"
          justifyContent="center"
          w="80px"
          h="80px"
          css={{
            '& .pet-category-svg': {
              zIndex: '15',
              position: 'relative',
            },
          }}
        >
          <ProhibitSvg
            className={css({
              position: 'absolute',
            })}
          />
          {petCategory === 'cat' ? (
            <CatSvg className="pet-category-svg" />
          ) : (
            <DogSvg className="pet-category-svg" />
          )}
        </Flex>
        <Text
          as="p"
          color="primary.900"
          font="serif"
          textAlign="center"
          textSize="headline6"
          maxW="320px"
          fontWeight="700"
        >
          {title}
        </Text>
        <Text as="p" textSize="body1" color="primary.900" textAlign="center">
          {content}
        </Text>
        <Grid gap="16px" w="100%">
          <Button variant="outlined" onClick={handleClose}>
            INDIETRO
          </Button>
          {renderWhatsappLink && (
            <Link to="https://wa.me/3517306666" target="_blank">
              SCRIVICI
            </Link>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
}
