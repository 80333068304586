import { Button } from '@/atoms/Button';
import { Link } from '@/atoms/Link';
import { Text } from '@/atoms/Text';
import { Dialog } from '@/molecoles';
import { Flex } from '@/panda/jsx/flex';
import { Grid } from '@/panda/jsx/grid';
import { create } from 'zustand';

import NewsletterSvg from './newsletter.svg';

type Store = {
  isOpen: boolean;
  context?: 'thank-you-page';
  handleOpen(context?: Store['context']): void;
  handleClose(): void;
};

export const useNewsletterSignupDialogStore = create<Store>((set) => {
  return {
    isOpen: false,
    handleOpen: (context) => set({ isOpen: true, context }),
    handleClose: () => set({ isOpen: false }),
  };
});

export function NewsletterSignupDialog() {
  const { isOpen, handleClose, context } = useNewsletterSignupDialogStore();
  return (
    <Dialog isOpen={isOpen} onClose={handleClose} maxW="480px">
      <Grid gap="16px" textAlign="center" justifyContent="center">
        <Text
          font="serif"
          fontWeight="700"
          color="primary.500"
          textSize="headline5"
        >
          Grazie per l'iscrizione!
        </Text>
        <Text textSize="body2" color="primary.700" maxW="295px">
          Useremo la tua email per informarti quando saremo nella tua zona!
        </Text>
        <Grid
          gap="16px"
          justifyItems="center"
          justifyContent="center"
          mt="16px"
        >
          {context === 'thank-you-page' ? (
            <Link to="https://pelomatto.it">TORNA ALLA HOME</Link>
          ) : (
            <Button onClick={handleClose}>Chiudi</Button>
          )}
          <Link
            variant="none"
            to={import.meta.env.VITE_PRIVACY_POLICY_URL}
            target="_blank"
            textDecoration="underline"
            textSize="body2"
          >
            Privacy policy
          </Link>
        </Grid>
        <Flex justifyContent="center">
          <NewsletterSvg />
        </Flex>
      </Grid>
    </Dialog>
  );
}
