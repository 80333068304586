import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const textRecipeFn = /* @__PURE__ */ createRecipe('text', {
  "textSize": "body1",
  "font": "roboto"
}, [])

const textRecipeVariantMap = {
  "textSize": [
    "headline1",
    "headline2",
    "headline3",
    "headline4",
    "headline5",
    "headline6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "button",
    "button-large",
    "caption",
    "overline",
    "pageTitle"
  ],
  "font": [
    "roboto",
    "serif"
  ],
  "variant": [
    "pageTitle"
  ]
}

const textRecipeVariantKeys = Object.keys(textRecipeVariantMap)

export const textRecipe = /* @__PURE__ */ Object.assign(memo(textRecipeFn), {
  __recipe__: true,
  __name__: 'textRecipe',
  raw: (props) => props,
  variantKeys: textRecipeVariantKeys,
  variantMap: textRecipeVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, textRecipeVariantKeys)
  },
})