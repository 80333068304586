import { styled } from '@/panda/jsx/factory';
import { Flex } from '@/panda/jsx/flex';
import {
  ButtonRecipeVariant,
  buttonRecipe,
} from '@/panda/recipes/button-recipe';
import { ConditionalValue } from '@/panda/types/conditions';
import cs from 'classnames';
import { ComponentProps, ReactNode, forwardRef } from 'react';

const Element = styled('button');

type Props = ComponentProps<typeof Element> & {
  variant?: ConditionalValue<ButtonRecipeVariant['variant']>;
  textSize?: ConditionalValue<ButtonRecipeVariant['textSize']>;
  rightIcon?: ReactNode;
};

export const Button = forwardRef<HTMLButtonElement, Props>(
  ({ children, variant, textSize, rightIcon, ...rest }, ref) => {
    return (
      <Element
        type="button"
        {...rest}
        className={cs(buttonRecipe({ variant, textSize }), rest.className)}
        ref={ref}
      >
        <span
          style={{
            zIndex: '10',
          }}
        >
          {children}
        </span>
        {rightIcon && <Flex className="right-icon-wrapper">{rightIcon}</Flex>}
      </Element>
    );
  },
);
