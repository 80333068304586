import { Text } from '@/atoms/Text';
import { useAppStore } from '@/store/Store';
import { formatCurrency } from '@/utils/formatCurrency';

export function TotalPrice() {
  const totalPrice = useAppStore((s) => s.totalPrice);

  return totalPrice ? (
    <Text p="4px 12px" textSize="body2" bg="primary.50" borderRadius="40px">
      Preventivo:{' '}
      <Text textSize="subtitle2" fontWeight="500">
        {formatCurrency(Number(totalPrice))}
      </Text>
    </Text>
  ) : null;
}
