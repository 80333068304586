import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const linkRecipeFn = /* @__PURE__ */ createRecipe('link', {
  "textSize": "body1",
  "variant": "primary"
}, [])

const linkRecipeVariantMap = {
  "textSize": [
    "headline1",
    "headline2",
    "headline3",
    "headline4",
    "headline5",
    "headline6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "button",
    "button-large",
    "caption",
    "overline",
    "pageTitle"
  ],
  "variant": [
    "none",
    "primary",
    "outlined"
  ]
}

const linkRecipeVariantKeys = Object.keys(linkRecipeVariantMap)

export const linkRecipe = /* @__PURE__ */ Object.assign(memo(linkRecipeFn), {
  __recipe__: true,
  __name__: 'linkRecipe',
  raw: (props) => props,
  variantKeys: linkRecipeVariantKeys,
  variantMap: linkRecipeVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, linkRecipeVariantKeys)
  },
})