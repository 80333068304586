import { PetCategoriesSchema, PetHairLengths, PetSizes } from '@/store/Store';
import { z } from 'zod';

import { fetchData, setQueryParamsToUrl } from './fetch';

export type ServicesAPIResponse = {
  result: {
    success: boolean;
    data: {
      services: Service[];
    };
  };
};

export type Service = {
  _id: string;
  name: string;
  isAvailable: boolean;
  category: string;
  petCategory: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  description: string;
  id: string;
  minPrice: string;
};

const DogVariables = z.object({
  petCategory: z.literal(PetCategoriesSchema.Enum.dog),
  petHairLength: PetHairLengths,
  petSize: PetSizes,
});
const CatVariables = z.object({
  petCategory: z.literal(PetCategoriesSchema.Enum.cat),
  petHairLength: PetHairLengths,
  petSize: z.literal(PetSizes.Enum.one),
});

export const ServicesVariableTypes = DogVariables.or(CatVariables);

export type ServicesQueryVariables = z.infer<typeof ServicesVariableTypes>;

type SuccessQueryResponse = {
  success: true;
  data: Service[];
};
type FailedQueryResponse = {
  success: false;
  error: string;
};

type Props = {
  variables: ServicesQueryVariables;
};

export async function getServices({
  variables,
}: Props): Promise<SuccessQueryResponse | FailedQueryResponse> {
  const validateData = ServicesVariableTypes.safeParse(variables);

  if (validateData.success) {
    const url = new URL(`${import.meta.env.VITE_API_URL}/services`);

    url.searchParams.append('isAvailable', 'true');

    setQueryParamsToUrl(url, variables);

    const res = await fetchData<ServicesAPIResponse>({
      url,
      operationName: 'GetServices',
      type: 'Query',
    });

    return {
      success: true,
      data: res.result.data.services,
    };
  }

  return {
    success: false,
    error: validateData.error.message,
  };
}
