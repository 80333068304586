import { Footer } from '@/organisms/Footer';
import { Header } from '@/organisms/Header';
import { NewsletterSignupDialog } from '@/organisms/NewsletterSignupDialog';
import { NoResultsDialog } from '@/organisms/NoResultsDialog';
import { styled } from '@/panda/jsx/factory';
import { Flex } from '@/panda/jsx/flex';
import { UpdateStore } from '@/routes/RootLayout/UpdateStore';
import { Outlet } from 'react-router-dom';

import { Analytics } from './Analytics';

export function RootLayout() {
  return (
    <>
      <Analytics />
      <NewsletterSignupDialog />
      <NoResultsDialog />
      <UpdateStore />
      <Flex flexDir="column" flex="1" h="100%" bg="primary.50">
        <Header />
        <styled.main
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          flex="1"
          overflowY="auto"
          w="100%"
        >
          <Flex
            justifyContent="center"
            w="100%"
            px="16px"
            py={{
              base: '24px',
              md: '40px',
            }}
          >
            <Outlet />
          </Flex>
        </styled.main>
        <Footer />
      </Flex>
    </>
  );
}
