import { logger } from '@/utils/logger';

import { DataLayerObject } from './types';

export function trackEvent(event: DataLayerObject) {
  window.dataLayer = window.dataLayer || [];

  logger({
    type: 'info',
    message: '[GA Event]:',
    payload: event,
  });

  window.dataLayer.push(event);
}
