import { withoutSpace } from '../helpers.mjs';

const conditionsStr = "_hover,_focus,_focusWithin,_focusVisible,_disabled,_active,_visited,_target,_readOnly,_readWrite,_empty,_checked,_enabled,_expanded,_highlighted,_before,_after,_firstLetter,_firstLine,_marker,_selection,_file,_backdrop,_first,_last,_only,_even,_odd,_firstOfType,_lastOfType,_onlyOfType,_peerFocus,_peerHover,_peerActive,_peerFocusWithin,_peerFocusVisible,_peerDisabled,_peerChecked,_peerInvalid,_peerExpanded,_peerPlaceholderShown,_groupFocus,_groupHover,_groupActive,_groupFocusWithin,_groupFocusVisible,_groupDisabled,_groupChecked,_groupExpanded,_groupInvalid,_indeterminate,_required,_valid,_invalid,_autofill,_inRange,_outOfRange,_placeholder,_placeholderShown,_pressed,_selected,_default,_optional,_open,_closed,_fullscreen,_loading,_currentPage,_currentStep,_motionReduce,_motionSafe,_print,_landscape,_portrait,_dark,_light,_osDark,_osLight,_highContrast,_lessContrast,_moreContrast,_ltr,_rtl,_scrollbar,_scrollbarThumb,_scrollbarTrack,_horizontal,_vertical,base,baseOnly,baseDown,xxs,xxsOnly,xxsDown,xsm,xsmOnly,xsmDown,xs,xsOnly,xsDown,sm,smOnly,smDown,md,mdOnly,mdDown,lg,lgOnly,lgDown,lgx,lgxOnly,lgxDown,lgxx,lgxxOnly,lgxxDown,2xl,2xlOnly,2xlDown,xl,xlOnly,xlDown,baseToXxs,baseToXsm,baseToXs,baseToSm,baseToMd,baseToLg,baseToLgx,baseToLgxx,baseTo2xl,baseToXl,xxsToXsm,xxsToXs,xxsToSm,xxsToMd,xxsToLg,xxsToLgx,xxsToLgxx,xxsTo2xl,xxsToXl,xsmToXs,xsmToSm,xsmToMd,xsmToLg,xsmToLgx,xsmToLgxx,xsmTo2xl,xsmToXl,xsToSm,xsToMd,xsToLg,xsToLgx,xsToLgxx,xsTo2xl,xsToXl,smToMd,smToLg,smToLgx,smToLgxx,smTo2xl,smToXl,mdToLg,mdToLgx,mdToLgxx,mdTo2xl,mdToXl,lgToLgx,lgToLgxx,lgTo2xl,lgToXl,lgxToLgxx,lgxTo2xl,lgxToXl,lgxxTo2xl,lgxxToXl,2xlToXl,@/xs,@/sm,@/md,@/lg,@/xl,@/2xl,@/3xl,@/4xl,@/5xl,@/6xl,@/7xl,@/8xl,base"
const conditions = new Set(conditionsStr.split(','))

export function isCondition(value){
  return conditions.has(value) || /^@|&|&$/.test(value)
}

const underscoreRegex = /^_/
const conditionsSelectorRegex = /&|@/

export function finalizeConditions(paths){
  return paths.map((path) => {
    if (conditions.has(path)){
      return path.replace(underscoreRegex, '')
    }

    if (conditionsSelectorRegex.test(path)){
      return `[${withoutSpace(path.trim())}]`
    }

    return path
  })}

  export function sortConditions(paths){
    return paths.sort((a, b) => {
      const aa = isCondition(a)
      const bb = isCondition(b)
      if (aa && !bb) return 1
      if (!aa && bb) return -1
      return 0
    })
  }