import { styled } from '@/panda/jsx/factory';
import { Flex } from '@/panda/jsx/flex';
import { LinkRecipeVariant, linkRecipe } from '@/panda/recipes/link-recipe';
import { ConditionalValue } from '@/panda/types/conditions';
import cs from 'classnames';
import { ComponentProps, ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

const Element = styled(ReactRouterLink);

type Props = ComponentProps<typeof Element> & {
  variant?: ConditionalValue<LinkRecipeVariant['variant']>;
  textSize?: ConditionalValue<LinkRecipeVariant['textSize']>;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
};

export function Link({
  children,
  variant,
  textSize,
  leftIcon,
  rightIcon,
  className,
  ...rest
}: Props) {
  return (
    <Element
      {...rest}
      className={cs(linkRecipe({ variant, textSize }), className)}
    >
      {leftIcon && <Flex className="left-icon-wrapper">{leftIcon}</Flex>}
      <span
        style={{
          zIndex: '10',
        }}
      >
        {children}
      </span>
      {rightIcon && <Flex className="right-icon-wrapper">{rightIcon}</Flex>}
    </Element>
  );
}
