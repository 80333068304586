import { Button } from '@/atoms/Button';
import { Input } from '@/atoms/Input';
import { Text } from '@/atoms/Text';
import ArrowBackSvg from '@/icons/arrow_back.svg';
import LocalizationSvg from '@/icons/localization.svg';
import SadSvg from '@/icons/sad.svg';
import { Dialog } from '@/molecoles';
import { showToast } from '@/molecoles/Toast';
import { css } from '@/panda/css/css';
import { styled } from '@/panda/jsx/factory';
import { Flex } from '@/panda/jsx/flex';
import { Grid } from '@/panda/jsx/grid';
import { Combobox } from '@headlessui/react';
import { a, useTransition } from '@react-spring/web';
import { useClickAway, useDebounce, useMediaQuery } from '@uidotdev/usehooks';
import { ElementRef, ReactNode, useEffect, useRef, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import useKeypress from 'react-use-keypress';
import usePlacesAutocomplete, { GeocodeResult } from 'use-places-autocomplete';
import { getGeocode, getLatLng } from 'use-places-autocomplete';

import { FormState } from './Step3';

const SpringBox = styled(a.div);

const StyledOption = styled(Combobox.Option);

type PopoverContentProps = {
  isOpen: boolean;
  children: ReactNode;
};

function PopoverContent({ isOpen, children }: PopoverContentProps) {
  const transition = useTransition(isOpen, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
    config: {
      tension: 180,
    },
  });

  return transition((style, item) => {
    if (!item) return null;
    return (
      <SpringBox
        style={style}
        position="absolute"
        top="calc(56px + 8px)"
        left="0"
        w="100%"
        zIndex="10"
      >
        {children}
      </SpringBox>
    );
  });
}

const loader = <Flex className="loader" flexShrink="0" my="16px" mx="auto" />;

const defaultComponents: FormState['location']['components'] = {
  city: '',
  province: '',
  provinceCode: '',
  region: '',
  postalCode: '',
  streetNumber: '',
  country: '',
  countryCode: '',
  route: '',
};

function getGooglePlacesComponents(
  components: GeocodeResult['address_components'],
) {
  return components.reduce<FormState['location']['components']>((acc, item) => {
    const type = item.types[0];

    if (type === 'administrative_area_level_3') {
      acc.city = item.long_name;
    } else if (type === 'administrative_area_level_2') {
      acc.province = item.long_name;
      acc.provinceCode = item.short_name;
    } else if (type === 'administrative_area_level_1') {
      acc.region = item.long_name;
    } else if (type === 'postal_code') {
      acc.postalCode = item.long_name;
    } else if (type.includes('street_number')) {
      acc.streetNumber = item.long_name;
    } else if (type.includes('country')) {
      acc.country = item.long_name;
      acc.countryCode = item.short_name;
    } else if (type.includes('route')) {
      acc.route = item.long_name;
    }

    return acc;
  }, defaultComponents);
}

type Props = {
  errorMessage: string | undefined;
  register: UseFormRegisterReturn<'location.address'>;
  initialValue: string;
  onChange(props: FormState['location']): void;
  onReset(): void;
};

export function GooglePlacesInput({
  errorMessage,
  register,
  onChange,
  initialValue,
  onReset,
}: Props) {
  const [openPopover, setOpenPopover] = useState(false);
  const [value, setValue] = useState(initialValue);
  const debouncedSearch = useDebounce(value, 600);
  const valueSelected = useRef(false);

  const isMobile = useMediaQuery('(max-width: 1024px)');
  const popoverInputRef = useRef<ElementRef<'input'>>(null);

  const ref = useClickAway<HTMLDivElement>(() => {
    if (isMobile) return;
    setOpenPopover(false);
  });

  const {
    setValue: setGaValue,
    suggestions: { status, data, loading },
    value: gaValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    defaultValue: initialValue,
    requestOptions: {
      language: 'it',
      types: ['address'],
      componentRestrictions: {
        country: 'it',
      },
    },
  });

  useKeypress('Escape', () => setOpenPopover(false));

  useEffect(() => {
    if (debouncedSearch && debouncedSearch !== gaValue) {
      setGaValue(debouncedSearch);
    }
  }, [debouncedSearch]);
  useEffect(() => {
    if ((status && !openPopover) || loading) {
      setOpenPopover(true);
    }
  }, [status, loading]);
  useEffect(() => {
    if (debouncedSearch && status === 'ZERO_RESULTS') {
      setOpenPopover(true);
    }
  }, [debouncedSearch, status]);
  useEffect(() => {
    if (!value && openPopover && !isMobile) {
      setOpenPopover(false);
    }
    if (!value) {
      setGaValue('', false);
      clearSuggestions();
      onReset();
    }
  }, [value]);
  useEffect(() => {
    if (openPopover && isMobile && popoverInputRef.current) {
      popoverInputRef.current.focus();
    }
  }, [openPopover]);

  const noResults = !loading && data.length === 0 && status === 'ZERO_RESULTS';

  async function handleOnSuggestionSelect(placeId: string) {
    let addressHasErrors = false;

    if (ref.current) {
      ref.current.querySelector('input')?.focus();
    }
    const placeGeoCoded = (await getGeocode({ placeId }))[0];
    const latLng = getLatLng(placeGeoCoded);

    const googlePlacesComponents = getGooglePlacesComponents(
      placeGeoCoded.address_components,
    );

    const selectedPlace = data.find((p) => p.place_id === placeId);

    if (selectedPlace?.description) {
      setValue(selectedPlace.description);
      setGaValue(selectedPlace.description, false);

      if (
        !googlePlacesComponents.streetNumber ||
        !googlePlacesComponents.postalCode
      ) {
        addressHasErrors = true;
      }

      const googleData = {
        address: selectedPlace.description,
        googlePlaceId: selectedPlace.place_id,
        components: googlePlacesComponents,
        coordinates: latLng,
      };

      onChange(googleData);

      return addressHasErrors;
    }
  }

  return (
    <>
      <Flex flexDir="column" position="relative" gridColumn="1 / -1" ref={ref}>
        <Combobox
          value={gaValue}
          onChange={async (placeId) => {
            try {
              valueSelected.current = true;
              await handleOnSuggestionSelect(placeId);
              valueSelected.current = false;
              setOpenPopover(false);
            } catch (error) {
              console.log(error);
              showToast('Si è verificato un errore; riprova.');
            }
          }}
        >
          <Combobox.Input
            as={Input}
            label="Indirizzo"
            rightIcon={<LocalizationSvg />}
            readOnly={isMobile}
            {...register}
            isErrored={!!errorMessage}
            additionalText={errorMessage}
            value={value}
            onBlur={(e) => {
              if (e.currentTarget.getAttribute('readonly') === null) {
                e.currentTarget.classList.remove('is-focused');
                setOpenPopover(false);
              }
            }}
            onFocus={(e) => {
              if (isMobile) return;

              if (e.currentTarget.getAttribute('readonly') === null) {
                e.currentTarget.classList.add('is-focused');
              }

              if (!status && initialValue) {
                return;
              }

              if (status && value && !valueSelected.current) {
                setOpenPopover(true);
              }
            }}
            onClick={(e) => {
              if (
                (!isMobile &&
                  status &&
                  value &&
                  e.currentTarget.classList.contains('is-focused')) ||
                isMobile
              ) {
                setOpenPopover(true);
              }
            }}
            onChange={(e) => {
              setValue(e.currentTarget.value);
            }}
          />
          <PopoverContent isOpen={!isMobile && openPopover}>
            <Combobox.Options static>
              <Grid
                gap="0px"
                bg="white"
                py="8px"
                boxShadow="0px 4px 15px rgba(0,0,0,0.2)"
                borderRadius="8px"
              >
                {noResults && (
                  <Text
                    textAlign="center"
                    p="32px 8px"
                    w="100%"
                    display="grid"
                    gap="4px"
                    justifyContent="center"
                    justifyItems="center"
                    css={{
                      '& svg': {
                        fill: 'primary.700',
                      },
                    }}
                  >
                    <SadSvg />
                    Nessun risultato
                  </Text>
                )}
                {!loading &&
                  data.map((suggestion) => {
                    const text = `${suggestion.structured_formatting.main_text} ${suggestion.structured_formatting.secondary_text}`;
                    return (
                      <StyledOption
                        w="100%"
                        key={text}
                        value={suggestion.place_id}
                      >
                        <Button
                          variant="none"
                          justifyContent="flex-start"
                          type="button"
                          textAlign="left"
                          p="12px 16px"
                          textSize="body2"
                          w="100%"
                          css={{
                            transition: 'background-color 200ms ease-out',
                            '&:hover, li[data-headlessui-state="active"] &': {
                              bg: 'primary.50',
                            },
                          }}
                        >
                          {text}
                        </Button>
                      </StyledOption>
                    );
                  })}
                {loading && loader}
              </Grid>
            </Combobox.Options>
          </PopoverContent>
        </Combobox>
      </Flex>
      {isMobile && (
        <Dialog
          isOpen={openPopover}
          w="100%"
          h="100%"
          maxW="100%"
          maxH="100%"
          mx="0px"
          borderRadius="0px"
          boxShadow="none"
          p="0px"
          containerClassName={css({
            p: '0px',
          })}
          onClose={() => {
            if (document.getElementById('toast-container')) return;
            setOpenPopover(false);
          }}
          dialogClassName={css({
            flex: '1',
            h: '100%',
          })}
          springConfig={{
            from: {
              x: '100%',
            },
            enter: {
              x: '0%',
            },
            leave: {
              x: '100%',
            },
            config: {
              tension: 240,
            },
          }}
        >
          <Flex p="16px" w="100%" flexDir="column">
            <Input
              placeholder="Indirizzo"
              rightIcon={<LocalizationSvg />}
              isErrored={!!errorMessage}
              additionalText={errorMessage}
              value={value}
              ref={popoverInputRef}
              leftIcon={
                <Button variant="none" onClick={() => setOpenPopover(false)}>
                  <ArrowBackSvg />
                </Button>
              }
              containerClassName={css({
                borderRadius: '40px',
              })}
              onChange={(e) => {
                setValue(e.currentTarget.value);
              }}
            />
            {noResults && (
              <Text
                textAlign="center"
                p="32px 8px"
                w="100%"
                display="grid"
                gap="4px"
                justifyContent="center"
                justifyItems="center"
                css={{
                  '& svg': {
                    fill: 'primary.700',
                  },
                }}
              >
                <SadSvg />
                Nessun risultato
              </Text>
            )}
            {loading && loader}
            {!loading && (
              <Grid gap="4px" w="100%" mt="12px">
                {data.map((suggestion) => {
                  const text = `${suggestion.structured_formatting.main_text} ${suggestion.structured_formatting.secondary_text}`;
                  return (
                    <Button
                      variant="none"
                      justifyContent="flex-start"
                      textAlign="left"
                      key={text}
                      p="12px 16px"
                      textSize="body2"
                      borderRadius="8px"
                      w="100%"
                      onClick={async () => {
                        await handleOnSuggestionSelect(suggestion.place_id);
                        setOpenPopover(false);
                      }}
                      css={{
                        transition: 'background-color 200ms ease-out',
                        '&:hover, li[data-headlessui-state="active"] &': {
                          bg: 'primary.50',
                        },
                      }}
                    >
                      {text}
                    </Button>
                  );
                })}
              </Grid>
            )}
          </Flex>
        </Dialog>
      )}
    </>
  );
}
