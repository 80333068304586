import { Text } from '@/atoms/Text';
import { MainRoot } from '@/routes/main';
import { ThankYouPage } from '@/routes/thank-you/ThankYouPage';
import {
  getDataFromLocalStorage,
  initializeStore,
  validateInternalStoreDataInput,
} from '@/store/Store';
import { AppStoreContext } from '@/store/index';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'dayjs/locale/it';
import React, { ReactNode, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { Link, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { redirect } from 'react-router-dom';

import { Toast } from './components/molecoles/Toast';
import { Flex, Grid } from './panda/jsx';
import { buttonRecipe } from './panda/recipes';
import { RootLayout } from './routes/RootLayout';
import { CatLayout } from './routes/cat';
import { DogLayout } from './routes/dog';
import './styles/global.css';

type Props = {
  children: ReactNode;
};

function App({ children }: Props) {
  const localStorageData = getDataFromLocalStorage();

  const storeRef = useRef(
    initializeStore(
      localStorageData
        ? validateInternalStoreDataInput(localStorageData) ?? {}
        : {},
    ),
  );

  return (
    <AppStoreContext.Provider value={storeRef.current}>
      {children}
    </AppStoreContext.Provider>
  );
}

let appInitialized = false;
let redirectWhenStoreIsEmpty = false;

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    loader: async () => {
      const store = getDataFromLocalStorage();
      if (
        window.location.pathname !== '/' &&
        appInitialized &&
        !store &&
        !redirectWhenStoreIsEmpty
      ) {
        redirectWhenStoreIsEmpty = true;
        return redirect('/');
      }

      if (window.location.pathname !== '/' && !appInitialized) {
        appInitialized = true;
        if (window.location.pathname.includes('thank-you')) {
          const url = new URL(window.location.href);
          const orderId = url.searchParams.get('orderId');

          if (!orderId) {
            return redirect('/');
          }

          return 'ok';
        }
        return redirect('/');
      }

      appInitialized = true;
      redirectWhenStoreIsEmpty = false;
      return 'ok';
    },
    errorElement: (
      <Flex
        flex="1"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid gap="32px" justifyItems="center">
          <Text textSize="headline5" fontWeight="700">
            404 - Pagina non trovata
          </Text>
          <Link
            to="/"
            className={buttonRecipe({
              variant: 'outlined',
            })}
          >
            Torna all'app
          </Link>
        </Grid>
      </Flex>
    ),
    children: [
      {
        index: true,
        path: '/',
        element: <MainRoot />,
      },
      {
        path: '/cane',
        element: <DogLayout />,
      },
      {
        path: '/gatto',
        element: <CatLayout />,
      },
      {
        path: '/:petCategory/thank-you',
        element: <ThankYouPage />,
      },
    ],
  },
]);

Sentry.init({
  dsn: 'https://0e3925570c396787b91fea96f1256b97@o4507259441446912.ingest.de.sentry.io/4507259448197200',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: import.meta.env.PROD,
});

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <App>
        <Toast />
        <RouterProvider router={router} />
      </App>
    </QueryClientProvider>
  </React.StrictMode>,
);
