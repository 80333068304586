import { Button } from '@/atoms/Button';
import { Link } from '@/atoms/Link';
import { Text } from '@/atoms/Text';
import PageNotFoundSvg from '@/icons/page-not-found.svg';
import { Dialog } from '@/molecoles';
import { showToast } from '@/molecoles/Toast';
import { useNewsletterSignupDialogStore } from '@/organisms/NewsletterSignupDialog';
import { Flex } from '@/panda/jsx/flex';
import { Grid } from '@/panda/jsx/grid';
import { Box } from '@/panda/jsx/index';
import { signupNewsletter } from '@/queries/signupNewsletter';
import { useAppStore } from '@/store/Store';
import { logger } from '@/utils/logger';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  location: string;
  onClose(): void;
};

export function NoLocationAvailable({ isOpen, onClose, location }: Props) {
  const [isSubscribing, setIsSubscribing] = useState(false);
  const handleOpenNewsletterSignupDialog = useNewsletterSignupDialogStore(
    (s) => s.handleOpen,
  );

  const newsletterVariables = useAppStore((s) => ({
    firstName: s.contactInfo?.firstName ?? '',
    lastName: s.contactInfo?.lastName ?? '',
    location: s.contactInfo?.location ?? null,
    email: s.contactInfo?.email ?? '',
  }));

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      maxW="480px"
      p={{
        base: '16px',
        lg: '24px',
      }}
      css={{
        '& .react-aria-Dialog': {
          display: 'grid',
          gap: '24px',
          justifyItems: 'center',
          alignContent: 'start',
          position: 'relative',
        },
      }}
    >
      {isSubscribing && (
        <Flex
          position="absolute"
          top="-8px"
          bottom="-8px"
          left="-16px"
          right="-16px"
          zIndex="20"
          justifyContent="center"
          alignItems="center"
          bg="rgba(255,255,255,0.94)"
          borderRadius="8px"
        >
          <Box className="loader" />
        </Flex>
      )}
      <Flex justifyContent="center">
        <PageNotFoundSvg />
      </Flex>
      <Text
        as="p"
        color="primary.900"
        font="serif"
        textAlign="center"
        textSize="headline6"
        maxW="320px"
        fontWeight="700"
      >
        Ooops! Purtroppo non serviamo ancora quest’area:
      </Text>
      <Text as="p" textAlign="center" textSize="body1" maxW="295px">
        {location}
      </Text>
      <Text as="p" textAlign="center" textSize="body1">
        Riprova o clicca qui sotto per iscriverti alla newsletter e rimanere
        aggiornato su quando saremo vicino a te!
      </Text>
      <Grid gap="16px">
        <Button variant="outlined" onClick={onClose}>
          CAMBIA INDIRIZZO
        </Button>
        <Button
          onClick={async () => {
            setIsSubscribing(true);
            try {
              const res = await signupNewsletter({
                variables: {
                  list: 'out-of-area',
                  ...newsletterVariables,
                },
              });
              if (res.success) {
                handleOpenNewsletterSignupDialog();
              } else {
                showToast(
                  res.error ?? 'Oooops! Si è verificato un errore, riprova!',
                  'error',
                );
              }
            } catch (error) {
              logger({
                message: '[Signup newsletter]',
                type: 'error',
                payload: error,
              });
            }
            setIsSubscribing(false);
          }}
        >
          ISCRIVIMI ALLA NEWSLETTER
        </Button>
      </Grid>
      <Flex justifyContent="center">
        <Link
          variant="none"
          to={import.meta.env.VITE_PRIVACY_POLICY_URL}
          target="_blank"
          textDecoration="underline"
          textSize="body2"
        >
          Privacy policy
        </Link>
      </Flex>
    </Dialog>
  );
}
