import { Radio, StyledRadioGroup } from '@/atoms/Radio';
import { Text } from '@/atoms/Text';
import { showToast } from '@/molecoles/Toast';
import { StepSectionLayout } from '@/organisms/StepSectionLayout';
import { Flex } from '@/panda/jsx/flex';
import { Grid } from '@/panda/jsx/grid';
import { getServices } from '@/queries/services';
import { PetCategoriesSchema, PetSizes } from '@/store/Store';
import {
  ServiceSchema,
  getPetHairLength,
  getPetSize,
  useAppStore,
} from '@/store/index';
import { formatCurrency } from '@/utils/formatCurrency';
import { logger } from '@/utils/logger';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

export function LoadingSkeleton() {
  return (
    <Flex
      h="66px"
      borderRadius="8px"
      bg="white"
      alignItems="center"
      justifyContent="space-between"
      border="1px solid token(colors.primary.300)"
      p="16px"
    >
      <Flex flexDir="column">
        <Flex w="60px" h="16px" borderRadius="4px" mb="8px" bg="primary.300" />
        <Flex w="120" h="16px" borderRadius="4px" bg="success" opacity="0.6" />
      </Flex>
      <Flex w="20px" h="20px" borderRadius="50%" bg="primary.300" />
    </Flex>
  );
}

export function Step1() {
  const setActiveService = useAppStore((s) => s.setActiveService);
  const activeService = useAppStore((s) => s.activeService);

  const petCategory = useAppStore((s) => s.petCategory);
  const selectedPetAttributes = useAppStore((s) => s.petAttributes);

  const petHairLength = getPetHairLength(selectedPetAttributes);
  const petSize = getPetSize(selectedPetAttributes);

  const variables = {
    ...(petCategory === 'dog'
      ? {
          petCategory: PetCategoriesSchema.Enum.dog,
          petHairLength: petHairLength!.value,
          petSize: petSize!.value,
        }
      : {
          petCategory: PetCategoriesSchema.Enum.cat,
          petHairLength: petHairLength!.value,
          petSize: PetSizes.Enum.one,
        }),
  };

  const { data, isLoading } = useQuery({
    queryKey: ['pet-services', variables],
    queryFn: async () => await getServices({ variables }),
  });

  const services = data?.success ? data.data : [];

  return (
    <StepSectionLayout title="Tipo di servizio">
      <StyledRadioGroup
        value={activeService?.id}
        onChange={(v) => {
          const newService = services.find((s) => s.id === v);
          const newServiceData: z.infer<typeof ServiceSchema> = {
            id: newService?.id ?? '',
            name: newService?.name ?? '',
            description: newService?.description ?? '',
            minPrice: newService?.minPrice ?? '',
          };
          const validatedData = ServiceSchema.safeParse(newServiceData);

          if (validatedData.success) {
            setActiveService(validatedData.data);
          } else {
            showToast(validatedData.error.message, 'error');
            logger({
              message: '[Select service validation]',
              type: 'error',
              payload: {
                service: newServiceData,
                error: validatedData.error,
              },
            });
          }
        }}
      >
        {isLoading && (
          <>
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
          </>
        )}
        {!isLoading &&
          services.map((s) => {
            return (
              <Radio key={s.id} value={s.id} py="8px">
                <Grid gap="4px">
                  <Text>{s.name}</Text>
                  <Text textSize="body2" color="success">
                    Prezzo: €
                    {formatCurrency(Number(s.minPrice)).replace('€', '')}
                  </Text>
                </Grid>
              </Radio>
            );
          })}
      </StyledRadioGroup>
    </StepSectionLayout>
  );
}
