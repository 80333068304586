import { Radio } from '@/atoms/Radio';
import { Text } from '@/atoms/Text';
import CatSvg from '@/icons/cat.svg';
import DogSvg from '@/icons/dog.svg';
import { css } from '@/panda/css/css';
import { Grid } from '@/panda/jsx/grid';
import { PetCategoriesSchema } from '@/store/Store';
import { useAppStore } from '@/store/index';
import { useSpring } from '@react-spring/web';
import { useEffect, useRef } from 'react';
import { RadioGroup } from 'react-aria-components';

export function MainRoot() {
  const { petCategory, setPetCategory } = useAppStore((s) => s);
  const initialStep = useRef(petCategory);
  const prevStep = useRef(petCategory);

  const [, setSpring] = useSpring(() => ({
    activeScale: 1,
    defaultScale: 1,
    config: {
      mass: 3.2,
      friction: 72,
      tension: 1440,
    },
  }));

  useEffect(() => {
    const dogSvg = document.querySelector<HTMLElement>('.dog-svg');
    const catSvg = document.querySelector<HTMLElement>('.cat-svg');

    if (petCategory && dogSvg && catSvg) {
      setSpring.start({
        immediate: !!initialStep.current,
        from: {
          activeScale: 1,
          defaultScale: !prevStep.current ? 1 : 1.1,
        },
        to: {
          activeScale: 1.1,
          defaultScale: 1,
        },
        onChange({ value }) {
          if (petCategory === 'dog') {
            dogSvg.style.transform = `scale(${value.activeScale})`;
            catSvg.style.transform = `scale(${value.defaultScale})`;
          } else {
            catSvg.style.transform = `scale(${value.activeScale})`;
            dogSvg.style.transform = `scale(${value.defaultScale})`;
          }
        },
      });
      initialStep.current = null;
      prevStep.current = petCategory;
    }
  }, [petCategory]);

  return (
    <Grid gap="40px" alignContent="start" w="100%" maxW="600px">
      <Text as="h1" variant="pageTitle">
        Hai un cane o un gatto?
      </Text>
      <RadioGroup
        value={petCategory ?? undefined}
        className={css({
          display: 'grid',
          gap: '16px',
          '& .radio-button': {
            textAlign: 'center',
            justifyContent: 'center',
            py: {
              base: '24px',
              md: '40px',
            },
            '& .text': {
              display: 'block',
              mt: '8px',
            },
            '& svg': {
              transition: 'filter',
              filter: 'grayscale(0.4)',
            },
            '&[data-selected=true] svg': {
              filter: 'grayscale(0)',
            },
          },
          '& .radio-box': {
            display: 'none',
          },
        })}
        onChange={(value) => {
          const parsedValue = PetCategoriesSchema.safeParse(value);
          if (parsedValue.success) {
            setPetCategory(parsedValue.data);
          }
        }}
      >
        <Radio value="dog">
          <DogSvg className="dog-svg" />
          <Text textSize="body1" color="primary.500">
            Cane
          </Text>
        </Radio>
        <Radio value="cat">
          <CatSvg className="cat-svg" />
          <Text textSize="body1" color="primary.500">
            Gatto
          </Text>
        </Radio>
      </RadioGroup>
    </Grid>
  );
}
